<script setup lang="ts" generic="T">
import { Form as VeeForm, ErrorMessage } from 'vee-validate'
import { Btn, Loading } from '@/components'
import { FormSchema, DynamicField } from '.'
import { computed, ref } from 'vue'

type IndexRecord = Record<number | string | symbol, unknown>

const props = defineProps<{
  schema: FormSchema<T>
  initialValues?: T
  submitText?: string
  cancelText?: string
  pending?: boolean
  submitBtnProps?: object
  cancelBtnProps?: object
  submitInitialValues?: boolean
  invalidSubmit?: boolean // can submit an invalid form
}>()

const emit = defineEmits<{
  (event: 'cancel'): void
  (event: 'submit', value: T): void
}>()

// TODO: handle datetime-local input
// TODO: handle number input
// TODO: handle range input?
// TODO: make all fields disabled when form is pending
// TODO: make disabled prop
// TODO: <field>_field-class
// TODO: <field>_label-class
const stringifyValues = computed(() => {
  const values = props.initialValues

  return props.schema.fields.reduce((acc, field) => {
    acc[field.name] =
      field.type === 'date'
        ? new Date(values?.[field.name] as string).toISOString().split('T')[0]
        : values?.[field.name]

    return acc
  }, {} as IndexRecord)
})

const submit = (values: IndexRecord) => {
  const normalized = props.schema.fields.reduce((acc, field) => {
    acc[field.name] =
      field.type === 'date'
        ? new Date(values[field.name] as string)
        : values[field.name]

    return acc
  }, {} as IndexRecord)

  emit('submit', {
    ...(props.submitInitialValues ? (props.initialValues as IndexRecord) : {}),
    ...normalized,
  } as T)
}

const form = ref<InstanceType<typeof VeeForm> | null>(null)
const reset = () => {
  if (!form.value) return

  form.value.resetForm()
}

defineExpose({ reset })
</script>

<template>
  <VeeForm
    ref="form"
    v-slot="{ handleSubmit, setFieldValue, meta }"
    as=""
    :schema="schema"
    :validation-schema="schema.rules"
    :initial-values="stringifyValues"
  >
    <form
      class="space-y-3"
      :class="$attrs.class"
      @submit="handleSubmit($event, submit)"
    >
      <div v-for="(field, i) in schema.fields" :key="field.name">
        <slot
          :name="`field_${String(field.name)}`"
          :field="field"
          :index="i"
          :fields="schema.fields"
          :schema="schema"
          :set-field-value="setFieldValue"
        >
          <DynamicField :field="field" />
        </slot>

        <ErrorMessage
          :name="String(field.name)"
          class="mt-1 block text-sm text-danger-500"
        />
      </div>

      <footer
        v-if="$slots.actions || submitText || cancelText"
        class="!mt-5 flex items-center gap-2"
      >
        <slot
          name="actions"
          :pending="pending"
          :submit="submit"
          :reset="reset"
          :submit-text="submitText"
          :cancel-text="cancelText"
          :submit-btn-props="submitBtnProps"
          :cancel-btn-props="cancelBtnProps"
        >
          <Btn
            v-if="submitText"
            type="submit"
            variant="primary"
            class="flex items-center gap-3"
            :disabled="!invalidSubmit && !(meta.touched && meta.valid)"
            v-bind="submitBtnProps"
          >
            {{ submitText }}
            <Loading v-if="pending" />
          </Btn>
          <Btn
            v-if="cancelText"
            type="button"
            variant="secondary"
            outline
            v-bind="cancelBtnProps"
            @click="$emit('cancel')"
            >{{ cancelText }}</Btn
          >
        </slot>
      </footer>
    </form>
  </VeeForm>
</template>
