<script setup lang="ts">
import { useRouter } from 'vue-router'
import { Loading, PromptPanel } from '@/components'

const router = useRouter()

router.replace({
  name: 'logout',
})
</script>

<template>
  <PageLayout :title="$t('pages.home.title')">
    <PromptPanel>
      <Loading>{{ $t('messages.redirecting') }}</Loading>
    </PromptPanel>
  </PageLayout>
</template>
