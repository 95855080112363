/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AcceptedLicenseAgreement,
  AccessDeclaration,
  AssistantRoleAssignment,
  AssistantToSpeechLanguagePathologistRelationship,
  AuditRoleAssignment,
  AuthorizationDetails,
  BillingRoleAssignment,
  CaregiverRoleAssignment,
  ComplianceRoleAssignment,
  CustomerSuccessRoleAssignment,
  DeclarationsAccessListParams,
  DirectorRoleAssignment,
  EmailAddress,
  EmailPasswordCredentials,
  Error,
  Fail,
  Goal,
  GoalItem,
  GoalMetricIntervals,
  GoalState,
  GoalSummary,
  GoalsListParams,
  GoalsMetricsDetailParams,
  GoalsMetricsIntervalsDetailParams,
  IntervalGoalMetrics,
  IntervalRatingGoalMetrics,
  Language,
  LanguagesListParams,
  LicenseAgreement,
  LicenseAgreementAccept,
  LicenseAgreementMediaType,
  LicenseAgreementType,
  LicenseAgreementsAcceptsListParams,
  LicenseAgreementsListParams,
  LocalizedString,
  Organization,
  OrganizationType,
  OrganizationsListParams,
  OwnerRoleAssignment,
  Passcode,
  PasscodeCredentials,
  PasscodeType,
  Patient,
  PatientRecord,
  PatientRecordDefaults,
  PatientRoleAssignment,
  PatientToCaregiverRelationship,
  PatientToSpeechLanguagePathologistRelationship,
  PatientsListParams,
  Phoneme,
  PhonemeQuery,
  PracticeItem,
  Rating,
  RatingSource,
  RatingValue,
  Recording,
  RecordingMedia,
  RecordingMediaDetail,
  RecordingMediaLocation,
  RecordingsListParams,
  RecordingsRatingsListParams,
  RelationshipTypeEnum,
  RelationshipTypesArray,
  RelationshipsListParams,
  RoleEnum,
  RolesArray,
  RolesAssignmentsListParams,
  SelfDetails,
  Settings,
  SpeechLanguagePathologistRoleAssignment,
  SummaryActivityGoalMetrics,
  SummaryGoalMetrics,
  SummaryRatingGoalMetrics,
  TargetPhoneme,
  TargetPhonemeSpecification,
  TechnicalSupportRoleAssignment,
  User,
  UsersEmailAddressesListParams,
  UsersListParams,
  UsersRecordsPatientVersionsDetailParams,
  Utterance,
  UtteranceMediaLocation,
  UtteranceQuery,
  UtteranceType,
} from './'

// Error
export function defaultError(override: Partial<Error> = {}): Error {
  return {
    status: 'error',
    code: 0,
    message: '',
    meta: {},
    ...override,
  }
}

// Fail
export function defaultFail(override: Partial<Fail> = {}): Fail {
  return {
    status: 'fail',
    failure: '',
    code: 0,
    data: {},
    ...override,
  }
}

// Settings
export function defaultSettings(override: Partial<Settings> = {}): Settings {
  return {
    playMinutes: 0,
    playSeconds: 0,
    practiceCount: 0,
    automaticallyAdvance: false,
    provideFeedback: false,
    ...override,
  }
}

// EmailPasswordCredentials
export function defaultEmailPasswordCredentials(
  override: Partial<EmailPasswordCredentials> = {}
): EmailPasswordCredentials {
  return {
    organizationShortName: '',
    emailAddress: '',
    password: '',
    ...override,
  }
}

// PasscodeCredentials
export function defaultPasscodeCredentials(override: Partial<PasscodeCredentials> = {}): PasscodeCredentials {
  return {
    organizationShortName: '',
    passcode: '',
    ...override,
  }
}

// SelfDetails
export function defaultSelfDetails(override: Partial<SelfDetails> = {}): SelfDetails {
  return {
    organization: defaultOrganization(),
    user: defaultUser(),
    settings: defaultSettings(),
    roles: defaultRolesArray(),
    pendingLicenseAgreements: [],
    permissions: [],
    scopeOrder: [],
    ...override,
  }
}

// AuthorizationDetails
export function defaultAuthorizationDetails(override: Partial<AuthorizationDetails> = {}): AuthorizationDetails {
  return {
    accessToken: '',
    refreshToken: '',
    organization: defaultOrganization(),
    user: defaultUser(),
    settings: defaultSettings(),
    roles: defaultRolesArray(),
    pendingLicenseAgreements: [],
    permissions: [],
    scopeOrder: [],
    ...override,
  }
}

// AccessDeclaration
export function defaultAccessDeclaration(override: Partial<AccessDeclaration> = {}): AccessDeclaration {
  return {
    id: '',
    userId: '',
    userFullName: '',
    accessOrganizationId: '',
    accessOrganizationName: '',
    reason: '',
    expiresAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// TargetPhonemeSpecification
export function defaultTargetPhonemeSpecification(
  override: Partial<TargetPhonemeSpecification> = {}
): TargetPhonemeSpecification {
  return {
    phonemeId: '',
    initial: false,
    medial: false,
    final: false,
    ...override,
  }
}

// TargetPhoneme
export function defaultTargetPhoneme(override: Partial<TargetPhoneme> = {}): TargetPhoneme {
  return {
    phonemeId: '',
    phonemeTitle: '',
    initial: false,
    medial: false,
    final: false,
    ...override,
  }
}

// GoalItem
export function defaultGoalItem(override: Partial<GoalItem> = {}): GoalItem {
  return {
    id: '',
    utteranceId: '',
    utteranceType: defaultUtteranceType(),
    utteranceText: '',
    targetPhonemes: [],
    ...override,
  }
}

// GoalState
export function defaultGoalState(): GoalState {
  return 'draft'
}

// Goal
export function defaultGoal(override: Partial<Goal> = {}): Goal {
  return {
    id: '',
    userId: '',
    languageCode: '',
    title: '',
    imitation: false,
    state: defaultGoalState(),
    items: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// GoalSummary
export function defaultGoalSummary(override: Partial<GoalSummary> = {}): GoalSummary {
  return {
    id: '',
    userId: '',
    languageCode: '',
    title: '',
    imitation: false,
    state: defaultGoalState(),
    itemCount: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// PracticeItem
export function defaultPracticeItem(override: Partial<PracticeItem> = {}): PracticeItem {
  return {
    id: '',
    utteranceId: '',
    utteranceType: defaultUtteranceType(),
    utteranceText: '',
    imitation: false,
    expiresAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// SummaryActivityGoalMetrics
export function defaultSummaryActivityGoalMetrics(
  override: Partial<SummaryActivityGoalMetrics> = {}
): SummaryActivityGoalMetrics {
  return {
    total: 0,
    percentage: 100,
    ...override,
  }
}

// SummaryRatingGoalMetrics
export function defaultSummaryRatingGoalMetrics(
  override: Partial<SummaryRatingGoalMetrics> = {}
): SummaryRatingGoalMetrics {
  return {
    total: 0,
    percentage: 100,
    firstAt: new Date(),
    lastAt: new Date(),
    ...override,
  }
}

// SummaryGoalMetrics
export function defaultSummaryGoalMetrics(override: Partial<SummaryGoalMetrics> = {}): SummaryGoalMetrics {
  return {
    recordings: {
      total: 0,
      firstAt: new Date(),
      lastAt: new Date(),
    },
    ratings: {
      good: defaultSummaryRatingGoalMetrics(),
      fair: defaultSummaryRatingGoalMetrics(),
      poor: defaultSummaryRatingGoalMetrics(),
      unrated: defaultSummaryRatingGoalMetrics(),
    },
    activity: {
      daily: defaultSummaryActivityGoalMetrics(),
      weekly: defaultSummaryActivityGoalMetrics(),
      monthly: defaultSummaryActivityGoalMetrics(),
      yearly: defaultSummaryActivityGoalMetrics(),
    },
    ...override,
  }
}

// GoalMetricIntervals
export function defaultGoalMetricIntervals(): GoalMetricIntervals {
  return 'daily'
}

// IntervalRatingGoalMetrics
export function defaultIntervalRatingGoalMetrics(
  override: Partial<IntervalRatingGoalMetrics> = {}
): IntervalRatingGoalMetrics {
  return {
    total: 0,
    percentage: 100,
    ...override,
  }
}

// IntervalGoalMetrics
export function defaultIntervalGoalMetrics(override: Partial<IntervalGoalMetrics> = {}): IntervalGoalMetrics {
  return {
    startsAt: new Date(),
    recordings: {
      total: 0,
    },
    ratings: {
      good: defaultIntervalRatingGoalMetrics(),
      fair: defaultIntervalRatingGoalMetrics(),
      poor: defaultIntervalRatingGoalMetrics(),
      unrated: defaultIntervalRatingGoalMetrics(),
    },
    ...override,
  }
}

// LocalizedString
export function defaultLocalizedString(override: Partial<LocalizedString> = {}): LocalizedString {
  return {
    code: '',
    value: '',
    ...override,
  }
}

// Language
export function defaultLanguage(override: Partial<Language> = {}): Language {
  return {
    code: '',
    name: [],
    ...override,
  }
}

// Phoneme
export function defaultPhoneme(override: Partial<Phoneme> = {}): Phoneme {
  return {
    id: '',
    parentId: '',
    index: 0,
    title: '',
    example: '',
    codes: [],
    children: [],
    ...override,
  }
}

// PhonemeQuery
export function defaultPhonemeQuery(override: Partial<PhonemeQuery> = {}): PhonemeQuery {
  return {
    phonemeId: '',
    initial: false,
    medial: false,
    final: false,
    occursOnce: false,
    occursMany: false,
    cluster: 'include',
    ...override,
  }
}

// UtteranceType
export function defaultUtteranceType(): UtteranceType {
  return 'word'
}

// UtteranceQuery
export function defaultUtteranceQuery(override: Partial<UtteranceQuery> = {}): UtteranceQuery {
  return {
    includePhonemeQueries: [],
    excludePhonemeIds: [],
    utteranceTypes: [],
    search: null,
    syllablesMin: 0,
    syllablesMax: 0,
    wordsMin: 0,
    wordsMax: 0,
    pageIndex: 0,
    pageSize: 0,
    ...override,
  }
}

// Utterance
export function defaultUtterance(override: Partial<Utterance> = {}): Utterance {
  return {
    id: '',
    type: defaultUtteranceType(),
    text: '',
    wordCount: 0,
    syllableCount: 0,
    ...override,
  }
}

// UtteranceMediaLocation
export function defaultUtteranceMediaLocation(override: Partial<UtteranceMediaLocation> = {}): UtteranceMediaLocation {
  return {
    method: 'GET',
    url: '',
    expiresAt: new Date(),
    ...override,
  }
}

// LicenseAgreementAccept
export function defaultLicenseAgreementAccept(override: Partial<LicenseAgreementAccept> = {}): LicenseAgreementAccept {
  return {
    id: '',
    licenseAgreementId: '',
    organizationId: '',
    organizationName: '',
    userId: '',
    userFullName: '',
    createdAt: new Date(),
    ...override,
  }
}

// AcceptedLicenseAgreement
export function defaultAcceptedLicenseAgreement(): AcceptedLicenseAgreement {
  return {
    ...defaultLicenseAgreement(),
    ...{
      accepts: [],
    },
  }
}

// LicenseAgreementType
export function defaultLicenseAgreementType(): LicenseAgreementType {
  return 'provider'
}

// LicenseAgreementMediaType
export function defaultLicenseAgreementMediaType(): LicenseAgreementMediaType {
  return 'text/plain'
}

// LicenseAgreement
export function defaultLicenseAgreement(override: Partial<LicenseAgreement> = {}): LicenseAgreement {
  return {
    id: '',
    type: defaultLicenseAgreementType(),
    current: false,
    mediaType: defaultLicenseAgreementMediaType(),
    body: '',
    publishedAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// OrganizationType
export function defaultOrganizationType(): OrganizationType {
  return 'standard'
}

// Organization
export function defaultOrganization(override: Partial<Organization> = {}): Organization {
  return {
    id: '',
    active: false,
    name: '',
    shortName: '',
    defaultLanguageCode: null,
    type: defaultOrganizationType(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// Patient
export function defaultPatient(override: Partial<Patient> = {}): Patient {
  return {
    userId: '',
    fullName: '',
    active: false,
    activeAt: new Date(),
    birthYear: 0,
    birthMonth: 0,
    ageYears: 0,
    ageMonths: 0,
    defaultLanguage: null,
    language: null,
    roles: defaultRolesArray(),
    ...override,
  }
}

// RecordingMediaDetail
export function defaultRecordingMediaDetail(override: Partial<RecordingMediaDetail> = {}): RecordingMediaDetail {
  return {
    mediaType: '',
    fileType: '',
    contentLength: 0,
    ...override,
  }
}

// RecordingMedia
export function defaultRecordingMedia(override: Partial<RecordingMedia> = {}): RecordingMedia {
  return {
    id: '',
    mediaType: '',
    fileType: '',
    contentLength: 0,
    ...override,
  }
}

// Recording
export function defaultRecording(override: Partial<Recording> = {}): Recording {
  return {
    id: '',
    userId: '',
    userFullName: '',
    goalId: '',
    goalTitle: '',
    utteranceId: '',
    utteranceType: defaultUtteranceType(),
    utteranceText: '',
    imitation: false,
    targetPhonemes: [],
    latestRating: null,
    media: [],
    seconds: 0,
    recordedAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// RecordingMediaLocation
export function defaultRecordingMediaLocation(override: Partial<RecordingMediaLocation> = {}): RecordingMediaLocation {
  return {
    method: 'GET',
    url: '',
    expiresAt: new Date(),
    ...override,
  }
}

// RatingValue
export function defaultRatingValue(): RatingValue {
  return 'good'
}

// RatingSource
export function defaultRatingSource(): RatingSource {
  return 'platform'
}

// Rating
export function defaultRating(override: Partial<Rating> = {}): Rating {
  return {
    id: '',
    recordingId: '',
    userId: null,
    userFullName: null,
    latest: false,
    value: defaultRatingValue(),
    source: defaultRatingSource(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// AssistantToSpeechLanguagePathologistRelationship
export function defaultAssistantToSpeechLanguagePathologistRelationship(
  override: Partial<AssistantToSpeechLanguagePathologistRelationship> = {}
): AssistantToSpeechLanguagePathologistRelationship {
  return {
    id: '',
    type: 'assistant-to-speech-language-pathologist',
    fromUserId: '',
    toUserId: '',
    toUserFullName: '',
    organizationId: '',
    assistantRoleAssignmentId: '',
    speechLanguagePathologistRoleAssignmentId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// RelationshipTypesArray
export function defaultRelationshipTypesArray(): RelationshipTypesArray {
  return []
}

// RelationshipTypeEnum
export function defaultRelationshipTypeEnum(): RelationshipTypeEnum {
  return 'assistant-to-speech-language-pathologist'
}

// PatientToCaregiverRelationship
export function defaultPatientToCaregiverRelationship(
  override: Partial<PatientToCaregiverRelationship> = {}
): PatientToCaregiverRelationship {
  return {
    id: '',
    type: 'patient-to-caregiver',
    fromUserId: '',
    toUserId: '',
    toUserFullName: '',
    organizationId: '',
    patientRoleAssignmentId: '',
    caregiverRoleAssignmentId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// PatientToSpeechLanguagePathologistRelationship
export function defaultPatientToSpeechLanguagePathologistRelationship(
  override: Partial<PatientToSpeechLanguagePathologistRelationship> = {}
): PatientToSpeechLanguagePathologistRelationship {
  return {
    id: '',
    type: 'patient-to-speech-language-pathologist',
    fromUserId: '',
    toUserId: '',
    toUserFullName: '',
    organizationId: '',
    patientRoleAssignmentId: '',
    speechLanguagePathologistRoleAssignmentId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// ComplianceRoleAssignment
export function defaultComplianceRoleAssignment(
  override: Partial<ComplianceRoleAssignment> = {}
): ComplianceRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'compliance',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// CustomerSuccessRoleAssignment
export function defaultCustomerSuccessRoleAssignment(
  override: Partial<CustomerSuccessRoleAssignment> = {}
): CustomerSuccessRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'customer-success',
    canUseAccessDeclarations: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// TechnicalSupportRoleAssignment
export function defaultTechnicalSupportRoleAssignment(
  override: Partial<TechnicalSupportRoleAssignment> = {}
): TechnicalSupportRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'technical-support',
    canUseAccessDeclarations: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// AssistantRoleAssignment
export function defaultAssistantRoleAssignment(
  override: Partial<AssistantRoleAssignment> = {}
): AssistantRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'assistant',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// AuditRoleAssignment
export function defaultAuditRoleAssignment(override: Partial<AuditRoleAssignment> = {}): AuditRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'audit',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// BillingRoleAssignment
export function defaultBillingRoleAssignment(override: Partial<BillingRoleAssignment> = {}): BillingRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'billing',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// DirectorRoleAssignment
export function defaultDirectorRoleAssignment(override: Partial<DirectorRoleAssignment> = {}): DirectorRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'director',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// OwnerRoleAssignment
export function defaultOwnerRoleAssignment(override: Partial<OwnerRoleAssignment> = {}): OwnerRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'owner',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// SpeechLanguagePathologistRoleAssignment
export function defaultSpeechLanguagePathologistRoleAssignment(
  override: Partial<SpeechLanguagePathologistRoleAssignment> = {}
): SpeechLanguagePathologistRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'speech-language-pathologist',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// CaregiverRoleAssignment
export function defaultCaregiverRoleAssignment(
  override: Partial<CaregiverRoleAssignment> = {}
): CaregiverRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'caregiver',
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// PatientRoleAssignment
export function defaultPatientRoleAssignment(override: Partial<PatientRoleAssignment> = {}): PatientRoleAssignment {
  return {
    id: '',
    userId: '',
    role: 'patient',
    active: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// RolesArray
export function defaultRolesArray(): RolesArray {
  return []
}

// RoleEnum
export function defaultRoleEnum(): RoleEnum {
  return 'assistant'
}

// User
export function defaultUser(override: Partial<User> = {}): User {
  return {
    id: '',
    organizationId: '',
    organizationName: '',
    active: false,
    fullName: '',
    primaryEmailAddress: null,
    roles: defaultRolesArray(),
    createdAt: new Date(),
    updatedAt: new Date(),
    activeAt: new Date(),
    ...override,
  }
}

// EmailAddress
export function defaultEmailAddress(override: Partial<EmailAddress> = {}): EmailAddress {
  return {
    id: '',
    userId: '',
    organizationId: '',
    original: '',
    normalized: '',
    primary: false,
    bounced: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// PasscodeType
export function defaultPasscodeType(): PasscodeType {
  return 'short'
}

// Passcode
export function defaultPasscode(override: Partial<Passcode> = {}): Passcode {
  return {
    id: '',
    userId: '',
    organizationId: '',
    type: defaultPasscodeType(),
    text: '',
    expiresAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// PatientRecord
export function defaultPatientRecord(override: Partial<PatientRecord> = {}): PatientRecord {
  return {
    id: '',
    userId: '',
    type: 'patient',
    latest: false,
    defaultLanguageCode: null,
    languageCode: null,
    birthMonth: 0,
    birthYear: 0,
    playMinutes: 0,
    playSeconds: 0,
    practiceCount: 0,
    automaticallyAdvance: false,
    provideFeedback: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override,
  }
}

// PatientRecordDefaults
export function defaultPatientRecordDefaults(override: Partial<PatientRecordDefaults> = {}): PatientRecordDefaults {
  return {
    language: defaultLanguage(),
    playSeconds: 0,
    practiceCount: 0,
    automaticallyAdvance: false,
    provideFeedback: false,
    ...override,
  }
}

// DeclarationsAccessListParams
export function defaultDeclarationsAccessListParams(
  override: Partial<DeclarationsAccessListParams> = {}
): DeclarationsAccessListParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    ...override,
  }
}

// GoalsListParams
export function defaultGoalsListParams(override: Partial<GoalsListParams> = {}): GoalsListParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    userId: '',
    state: defaultGoalState(),
    ...override,
  }
}

// GoalsMetricsDetailParams
export function defaultGoalsMetricsDetailParams(
  override: Partial<GoalsMetricsDetailParams> = {}
): GoalsMetricsDetailParams {
  return {
    timezone: '',
    id: '',
    ...override,
  }
}

// GoalsMetricsIntervalsDetailParams
export function defaultGoalsMetricsIntervalsDetailParams(
  override: Partial<GoalsMetricsIntervalsDetailParams> = {}
): GoalsMetricsIntervalsDetailParams {
  return {
    interval: defaultGoalMetricIntervals(),
    timezone: '',
    pageIndex: 0,
    pageSize: 0,
    id: '',
    ...override,
  }
}

// LanguagesListParams
export function defaultLanguagesListParams(override: Partial<LanguagesListParams> = {}): LanguagesListParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    ...override,
  }
}

// LicenseAgreementsAcceptsListParams
export function defaultLicenseAgreementsAcceptsListParams(
  override: Partial<LicenseAgreementsAcceptsListParams> = {}
): LicenseAgreementsAcceptsListParams {
  return {
    filterByOrganizationId: '',
    filterByUserId: '',
    filterByType: defaultLicenseAgreementType(),
    filterByPending: false,
    ...override,
  }
}

// LicenseAgreementsListParams
export function defaultLicenseAgreementsListParams(
  override: Partial<LicenseAgreementsListParams> = {}
): LicenseAgreementsListParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    filterByType: defaultLicenseAgreementType(),
    filterByCurrent: false,
    filterByPublished: false,
    ...override,
  }
}

// OrganizationsListParams
export function defaultOrganizationsListParams(
  override: Partial<OrganizationsListParams> = {}
): OrganizationsListParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    search: '',
    ...override,
  }
}

// PatientsListParams
export function defaultPatientsListParams(override: Partial<PatientsListParams> = {}): PatientsListParams {
  return {
    active: false,
    pageIndex: 0,
    pageSize: 0,
    search: '',
    ...override,
  }
}

// RecordingsListParams
export function defaultRecordingsListParams(override: Partial<RecordingsListParams> = {}): RecordingsListParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    filterByUserId: '',
    filterByGoalId: '',
    filterByLatestRatingSource: 'platform',
    filterByLatestRatingValue: 'none',
    ...override,
  }
}

// RecordingsRatingsListParams
export function defaultRecordingsRatingsListParams(
  override: Partial<RecordingsRatingsListParams> = {}
): RecordingsRatingsListParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    recordingId: '',
    ...override,
  }
}

// RelationshipsListParams
export function defaultRelationshipsListParams(
  override: Partial<RelationshipsListParams> = {}
): RelationshipsListParams {
  return {
    filterByOrganizationId: [],
    filterByRelationshipType: defaultRelationshipTypesArray(),
    filterByFromUserId: [],
    filterByToUserId: [],
    pageIndex: 0,
    pageSize: 0,
    ...override,
  }
}

// RolesAssignmentsListParams
export function defaultRolesAssignmentsListParams(
  override: Partial<RolesAssignmentsListParams> = {}
): RolesAssignmentsListParams {
  return {
    filterByOrganizationId: [],
    filterByRole: defaultRolesArray(),
    filterByUserId: [],
    pageIndex: 0,
    pageSize: 0,
    ...override,
  }
}

// UsersListParams
export function defaultUsersListParams(override: Partial<UsersListParams> = {}): UsersListParams {
  return {
    filterByOrganizationIds: [],
    filterByRoles: defaultRolesArray(),
    pageIndex: 0,
    pageSize: 0,
    search: '',
    ...override,
  }
}

// UsersEmailAddressesListParams
export function defaultUsersEmailAddressesListParams(
  override: Partial<UsersEmailAddressesListParams> = {}
): UsersEmailAddressesListParams {
  return {
    filterByOrganizationIds: [],
    filterByUserIds: [],
    search: '',
    pageIndex: 0,
    pageSize: 0,
    ...override,
  }
}

// UsersRecordsPatientVersionsDetailParams
export function defaultUsersRecordsPatientVersionsDetailParams(
  override: Partial<UsersRecordsPatientVersionsDetailParams> = {}
): UsersRecordsPatientVersionsDetailParams {
  return {
    pageIndex: 0,
    pageSize: 0,
    id: '',
    ...override,
  }
}
