<script setup lang="ts">
import { computed } from 'vue'
import { useApi, useQuery, useSearchQuery, DataParam } from '@/composables'
import {
  Badge,
  Btn,
  DetailList,
  DateTimeFormat,
  Panel,
  SearchBar,
  TransitionList,
} from '@/components'

const { term } = useSearchQuery('term')

const api = useApi()

const data = computed<DataParam<'usersList'>>(() => ({
  search: term.value || undefined,
}))

const { result } = useQuery(api.v1.usersList, data)

const users = computed(() => result.value?.data?.data?.items || [])

const total = computed(() => result.value?.data?.meta?.totalItems || 0)
const filteredTotal = computed(() => users.value.length)
</script>

<template>
  <PageLayout :title="$t('pages.users.title')">
    <template #actions>
      <Btn variant="primary" :to="{ name: 'users-create' }">{{
        $t('actions.create')
      }}</Btn>
    </template>

    <template #default>
      <SearchBar
        v-model="term"
        :count="filteredTotal"
        :total="total"
        class="mb-5"
      />

      <TransitionList v-slot="{ item: user }" :items="users" item-key="id">
        <Panel>
          <template #header>
            <router-link :to="{ name: 'users-edit', params: { id: user.id } }">
              {{ user.fullName }}
            </router-link>
          </template>

          <DetailList
            :entries="user"
            :fields="['roles', 'activeAt', 'organizationName']"
            i18n-prefix="details.users"
          >
            <template #roles-value="{ item }">
              <span v-if="item.roles?.length" class="flex flex-wrap gap-1">
                <Badge v-for="role in item.roles" :key="role">{{
                  $t(`roles.${role}`)
                }}</Badge>
              </span>
              <span v-else class="text-gray-400">
                {{ $t('messages.na') }}
              </span>
            </template>

            <template #activeAt-value="{ item }">
              <DateTimeFormat v-if="item.activeAt" :date="item.activeAt" />
              <span v-else>{{ $t('messages.na') }}</span>
            </template>
          </DetailList>
        </Panel>
      </TransitionList>
    </template>
  </PageLayout>
</template>
