<script setup lang="ts">
withDefaults(
  defineProps<{
    placeholder?: string
    count?: number
    total?: number
  }>(),
  {
    placeholder: 'Search...',
    count: 0,
    total: 0,
  }
)

// eslint-disable-next-line
const term = defineModel<string | null>()

const clearTerm = () => {
  term.value = null
}
</script>

<template>
  <section>
    <label for="search" class="sr-only">Search</label>
    <input
      id="search"
      v-model="term"
      type="search"
      :placeholder="placeholder"
    />

    <div class="text-muted mt-1 text-sm">
      {{ $t('forms.filter.resultCount', { count, total }) }}

      <button
        v-if="term"
        type="button"
        class="px-2 text-primary-500"
        @click="clearTerm"
      >
        {{ $t('actions.clear') }}
      </button>
    </div>
  </section>
</template>
