<script setup lang="ts">
import { Btn, Panel, Badge } from '@/components'
import { useApi, useQuery } from '@/composables'
import { LicenseAgreementsListParams, LicenseAgreementType } from '@/models'
import { formatDateTime, truncate } from '@/utils'
import { useRouteQuery } from '@vueuse/router'
import { computed, Ref } from 'vue'

const api = useApi()

const agreementType: Ref<LicenseAgreementType> = useRouteQuery(
  'type',
  'enduser',
  {
    // use the first value if it's an array
    transform: (val: string | string[] | null) => {
      const value = (Array.isArray(val) ? val[0] : val ?? '')
        .trim()
        .toLowerCase()
      if (value === 'provider') return 'provider'
      return 'enduser'
    },
  }
)

const params = computed<LicenseAgreementsListParams>(() => ({
  filterByType: agreementType.value,
}))

const { transformed: agreements } = useQuery(
  api.v1.licenseAgreementsList,
  params,
  {
    transform: (data) => data?.data.data?.items || [],
  }
)
</script>

<template>
  <PageLayout :title="$t('pages.licenseAgreements.title')">
    <template #actions>
      <Btn variant="primary" :to="{ name: 'license-agreements-create' }">
        {{ $t('actions.create') }}
      </Btn>
    </template>

    <template #default>
      <div class="mb-5 flex">
        <div>
          <input
            id="type-enduser"
            v-model="agreementType"
            value="enduser"
            type="radio"
            name="scope"
            class="peer sr-only"
          />
          <label
            for="type-enduser"
            class="block w-full cursor-pointer border-b-4 border-transparent p-2 text-center peer-checked:!border-primary-500 dark:hover:border-secondary-700"
          >
            {{ $t('pages.licenseAgreements.enduserAgreements') }}
          </label>
        </div>

        <div>
          <input
            id="type-provider"
            v-model="agreementType"
            value="provider"
            type="radio"
            name="scope"
            class="peer sr-only"
          />
          <label
            for="type-provider"
            class="block w-full cursor-pointer border-b-4 border-transparent p-2 text-center peer-checked:!border-primary-500 dark:hover:border-secondary-700"
          >
            {{ $t('pages.licenseAgreements.providerAgreements') }}
          </label>
        </div>
      </div>

      <Panel v-for="agreement in agreements" :key="agreement.id" class="mb-3">
        <template #header>
          <div class="flex">
            <router-link
              :to="{
                name: 'license-agreements-edit',
                params: { id: agreement.id },
              }"
            >
              {{
                agreement.publishedAt
                  ? $t('pages.licenseAgreements.publishedAt', {
                      date: formatDateTime(agreement.publishedAt),
                    })
                  : $t('pages.licenseAgreements.createdAt', {
                      date: formatDateTime(agreement.createdAt),
                    })
              }}
            </router-link>

            <Badge v-if="agreement.current" variant="primary" class="ml-auto">
              {{ $t('pages.licenseAgreements.current') }}
            </Badge>
            <Badge v-if="!agreement.publishedAt" class="ml-auto">
              {{ $t('pages.licenseAgreements.draft') }}
            </Badge>
          </div>
        </template>

        <template #default>
          <p
            class="max-h-[10rem] overflow-hidden whitespace-pre-wrap bg-gradient-to-b from-black via-black via-[6rem] bg-clip-text text-transparent"
          >
            {{ truncate(agreement.body, 1000) }}
          </p>
        </template>
      </Panel>
    </template>
  </PageLayout>
</template>
