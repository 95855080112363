import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from './store'

import Index from '@/pages/Index.vue'

import OrganizationsIndex from '@/pages/organizations/Index.vue'
import OrganizationsCreate from '@/pages/organizations/Create.vue'
import OrganizationsEdit from '@/pages/organizations/Edit.vue'

import UsersIndex from '@/pages/users/Index.vue'
import UsersCreate from '@/pages/users/Create.vue'
import UsersEdit from '@/pages/users/Edit.vue'

import AccessDeclarationsIndex from '@/pages/access-declarations/Index.vue'

import LicenseAgreementsIndex from '@/pages/license-agreements/Index.vue'
import LicenseAgreementsCreate from '@/pages/license-agreements/Create.vue'
import LicenseAgreementsEdit from '@/pages/license-agreements/Edit.vue'

import Login from '@/pages/Login.vue'
import Logout from '@/pages/Logout.vue'
import TestBench from '@/pages/bench.vue'
import NotFound from '@/pages/404.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Index,
      name: 'home',
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/organizations',
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: OrganizationsIndex,
          name: 'organizations',
        },
        {
          path: 'create',
          component: OrganizationsCreate,
          name: 'organizations-create',
        },
        {
          path: ':id',
          component: OrganizationsEdit,
          name: 'organizations-edit',
        },
      ],
    },

    {
      path: '/users',
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: UsersIndex,
          name: 'users',
        },
        {
          path: 'create',
          component: UsersCreate,
          name: 'users-create',
        },
        {
          path: ':id',
          component: UsersEdit,
          name: 'users-edit',
        },
      ],
    },

    {
      path: '/access-declarations',
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: AccessDeclarationsIndex,
          name: 'access-declarations',
        },
      ],
    },

    {
      path: '/license-agreements',
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: LicenseAgreementsIndex,
          name: 'license-agreements',
        },
        {
          path: '/create',
          component: LicenseAgreementsCreate,
          name: 'license-agreements-create',
        },
        {
          path: '/:id',
          component: LicenseAgreementsEdit,
          name: 'license-agreements-edit',
        }
      ],
    },

    {
      path: '/login',
      component: Login,
      name: 'login',
      meta: {
        requiresNoAuth: true,
      },
    },
    {
      path: '/logout',
      component: Logout,
      name: 'logout',
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: '/bench',
      component: TestBench,
      name: 'bench',
    },

    // catch all 404
    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
      name: '404',
    },
  ],
})

router.beforeEach(async (to) => {
  // route guard, redirect to login if page requires auth and not logged in
  const authStore = useAuthStore()
  await authStore.tryEnsureLoggedIn()

  if (to.meta.requiresAuth && !authStore.self) return { name: 'login' }
  if (to.meta.requiresNoAuth && authStore.self) return { name: 'home' }
})

export default router
