import { Api, RequestParams } from '@/models'
import { key } from '@/plugins/apiClient'
import { AxiosError, AxiosResponse } from 'axios'
import { ComputedRef, MaybeRef, Ref, computed, inject, ref, toRef, toValue, watch } from 'vue'

export function useApi() {
  return inject(key) as Api<unknown>
}

interface useQueryReturnValue<TResponse, TModel> {
  result: Ref<AxiosResponse<TResponse>>
  transformed: ComputedRef<NonNullable<TModel> | undefined>
  error: Ref<AxiosError<TResponse>>
  loading: Ref<boolean>
  execute: () => Promise<void>
}

interface QueryOptions<TResponse, TModel> {
  manual?: boolean,
  transform?: (result: AxiosResponse<TResponse> | undefined) => TModel
}

export const useQuery = <TData, TResponse, TModel>(
  fn: (data: TData, params: RequestParams) => Promise<AxiosResponse<TResponse>>,
  data: MaybeRef<TData>,
  options?: QueryOptions<TResponse, TModel>
) => {
  const result = ref<AxiosResponse<TResponse>>()
  const transformed = computed(() => options?.transform?.(result.value))
  const error = ref<AxiosError<TResponse>>()
  const loading = ref(false)

  const execute = () => {
    if (loading.value) return

    loading.value = true

    return fn(toValue(data), {}).then(
      (r) => {
        loading.value = false
        result.value = r
      },
      (e: AxiosError<TResponse>) => {
        loading.value = false
        error.value = e
      }
    )
  }

  if (!options?.manual) {
    watch(toRef(data), execute, { immediate: true })
  }

  return { result, transformed, error, loading, execute } as useQueryReturnValue<
    TResponse | undefined, TModel
  >
}

export type DataParam<T extends keyof Api<unknown>['v1']> = Parameters<
  Api<unknown>['v1'][T]
>[0]
