import { z } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { FormSchema, FormSchemaField, FormSchemaSelectField } from '.'
import { Organization } from '@/models'
import { DataParam } from '@/composables'

interface Params {
  languageOptions: Array<{ label: string; value: string }>
}

const baseOrganizationFields = (
  params: Params
): FormSchemaField<Organization>[] => [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    rules: toTypedSchema(z.string().min(1)),
  },
  {
    name: 'shortName',
    label: 'Short Name',
    type: 'text',
    help: 'Only lowercase letters and numbers allowed',
    rules: toTypedSchema(
      z
        .string()
        .regex(/^[a-z0-9]+$/, { message: 'Must be lowercase alphanumeric' })
    ),
  },
  {
    name: 'defaultLanguageCode',
    label: 'Default Language',
    as: 'select',
    options: params.languageOptions,
    rules: toTypedSchema(z.string().nullable()),
  },
]

const activeField: FormSchemaField<Organization> = {
  name: 'active',
  label: 'Active',
  type: 'checkbox',
  rules: toTypedSchema(z.boolean()),
}

const typeField: FormSchemaSelectField<Organization, 'type'> = {
  name: 'type',
  label: 'Type',
  as: 'select',
  options: [
    { label: 'Admin', value: 'admin', disabled: true },
    { label: 'Standard', value: 'standard' },
    { label: 'Demo', value: 'demo' },
  ],
  rules: toTypedSchema(z.string().min(1)),
}

export const organizationCreateSchema = (
  params: Params
): FormSchema<DataParam<'organizationsCreate'>> => ({
  fields: [
    ...baseOrganizationFields(params),
    activeField,
    {
      name: 'ownerFullName',
      label: 'Owner Full Name',
      type: 'text',
      rules: toTypedSchema(z.string().min(1)),
    },
    {
      name: 'ownerEmailAddress',
      label: 'Owner Email Address',
      type: 'email',
      rules: toTypedSchema(z.string().email()),
    },
  ],
})

export const organizationEditSchema = (
  params: Params
): FormSchema<Organization> => ({
  fields: [...baseOrganizationFields(params), typeField, activeField],
})
