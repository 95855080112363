<script setup lang="ts">
import { useRouter } from 'vue-router'
import { User, defaultUser } from '@/models'
import { ref } from 'vue'
import { DynamicForm, userCreateSchema } from '@/forms'
import { useApi } from '@/composables'

const router = useRouter()
const api = useApi()

const user = ref(defaultUser())

const submit = async (values: User) => {
  user.value = {
    ...user.value,
    ...values,
  }

  try {
    const response = await api.v1.usersCreate({
      fullName: user.value.fullName as string,
      emailAddress: user.value.primaryEmailAddress,
      organizationId: user.value.organizationId as string,
      active: user.value.active as boolean,
    })

    router.push({
      name: 'users-edit',
      params: { id: response.data.data?.item?.id },
    })
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <PageLayout
    :title="$t('pages.usersCreate.title')"
    :breadcrumbs="[{ text: $t('pages.users.title'), to: { name: 'users' } }]"
  >
    <DynamicForm
      v-if="user"
      :schema="userCreateSchema"
      :initial-values="user"
      :submit-text="$t('actions.save')"
      @submit="submit($event)"
    />
  </PageLayout>
</template>
