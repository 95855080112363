import { AxiosResponse } from 'axios'
import { Api, RoleEnum } from './'
import { DateLike } from '@vueuse/core'

export interface RoleAssignment {
  id?: string
  userId?: string
  role?: string
  canUseAccessDeclarations?: boolean
  createdAt?: DateLike
  updatedAt?: DateLike
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface RoleItem {
  id: RoleEnum
  name: string
  get: (...args: any[]) => Promise<AxiosResponse<unknown>>
  create: (...args: any[]) => Promise<AxiosResponse<unknown>>
  delete: (...args: any[]) => Promise<AxiosResponse<unknown>>
  update?: (...args: any[]) => Promise<AxiosResponse<unknown>>
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export const adminRoles = (api: Api<unknown>): RoleItem[] => [
  {
    id: 'compliance',
    name: 'Compliance',
    get: api.v1.rolesAssignmentsComplianceDetail,
    create: api.v1.rolesAssignmentsComplianceCreate,
    delete: api.v1.rolesAssignmentsComplianceDelete,
  },
  {
    id: 'customer-success',
    name: 'Customer Success',
    get: api.v1.rolesAssignmentsCustomerSuccessDetail,
    create: api.v1.rolesAssignmentsCustomerSuccessCreate,
    delete: api.v1.rolesAssignmentsCustomerSuccessDelete,
    update: api.v1.rolesAssignmentsCustomerSuccessUpdate,
  },
  {
    id: 'technical-support',
    name: 'Technical Support',
    get: api.v1.rolesAssignmentsTechnicalSupportDetail,
    create: api.v1.rolesAssignmentsTechnicalSupportCreate,
    delete: api.v1.rolesAssignmentsTechnicalSupportDelete,
    update: api.v1.rolesAssignmentsTechnicalSupportUpdate,
  },
]

export const standardRoles = (api: Api<unknown>): RoleItem[] => [
  {
    id: 'assistant',
    name: 'Assistant',
    get: api.v1.rolesAssignmentsAssistantDetail,
    create: api.v1.rolesAssignmentsAssistantCreate,
    delete: api.v1.rolesAssignmentsAssistantDelete,
  },
  {
    id: 'audit',
    name: 'Audit',
    get: api.v1.rolesAssignmentsAuditDetail,
    create: api.v1.rolesAssignmentsAuditCreate,
    delete: api.v1.rolesAssignmentsAuditDelete,
  },
  {
    id: 'billing',
    name: 'Billing',
    get: api.v1.rolesAssignmentsBillingDetail,
    create: api.v1.rolesAssignmentsBillingCreate,
    delete: api.v1.rolesAssignmentsBillingDelete,
  },
  {
    id: 'patient',
    name: 'Patient',
    get: api.v1.rolesAssignmentsPatientDetail,
    create: api.v1.rolesAssignmentsPatientCreate,
    delete: api.v1.rolesAssignmentsPatientDelete,
  },

  {
    id: 'director',
    name: 'Director',
    get: api.v1.rolesAssignmentsDirectorDetail,
    create: api.v1.rolesAssignmentsDirectorCreate,
    delete: api.v1.rolesAssignmentsDirectorDelete,
  },
  {
    id: 'owner',
    name: 'Owner',
    get: api.v1.rolesAssignmentsOwnerDetail,
    create: api.v1.rolesAssignmentsOwnerCreate,
    delete: api.v1.rolesAssignmentsOwnerDelete,
  },
  {
    id: 'caregiver',
    name: 'Caregiver',
    get: api.v1.rolesAssignmentsCaregiverDetail,
    create: api.v1.rolesAssignmentsCaregiverCreate,
    delete: api.v1.rolesAssignmentsCaregiverDelete,
  },
  {
    id: 'speech-language-pathologist',
    name: 'Speech Language Pathologist',
    get: api.v1.rolesAssignmentsSpeechLanguagePathologistDetail,
    create: api.v1.rolesAssignmentsSpeechLanguagePathologistCreate,
    delete: api.v1.rolesAssignmentsSpeechLanguagePathologistDelete,
  },
]
