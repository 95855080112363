import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from '@/router'
import { createPinia } from 'pinia'
import { Head, createHead } from '@vueuse/head'
import detectHiddenScrollbar from '@/plugins/detectHiddenScrollbar'
import apiClient from '@/plugins/apiClient'
import { PageLayout } from '@/layouts'
import * as Sentry from '@sentry/vue'
import '@/assets/index.css'

const app = createApp(App)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    // dsn: 'https://00271b5dc0134bf387eaa498f358b70d@o4504968930394112.ingest.sentry.io/4505138414485504',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['https://portal.speaksuite.com'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // performance tracking on all components instead of just root
    trackComponents: import.meta.env.VITE_SENTRY_TRACK_COMPONENTS === 'true',
  })
}

app.use(i18n)
app.use(
  createHead({
    titleTemplate: (title) => (title ? `${title} - ` : '') + 'Console',
  })
)
app.use(detectHiddenScrollbar)
app.use(createPinia())
app.use(apiClient, { baseURL: import.meta.env.VITE_API_BASE_URL })
app.use(router)

app.component('UseHead', Head)
app.component('PageLayout', PageLayout)

app.mount('#app')
