<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { computedAsync, useCloned } from '@vueuse/core'
import { useRouteParams } from '@vueuse/router'
import {
  User,
  RoleAssignment,
  adminRoles,
  standardRoles,
  EmailAddress,
  defaultEmailAddress,
} from '@/models'
import { useApi, useQuery } from '@/composables'
import { Btn, EditList, Panel, Badge } from '@/components'
import { DynamicForm, userEditSchema, userEmailSchema } from '@/forms'

const router = useRouter()
const api = useApi()
const userId = useRouteParams<string>('id')

const { result, error } = useQuery(api.v1.usersDetail, userId)

const user = computed(() => result.value?.data?.data?.item)
watchEffect(() => error.value && router.replace({ name: '404' }))

const userOrg = computedAsync(async () => {
  if (!user.value?.organizationId) return undefined
  const result = await api.v1.organizationsDetail(user.value?.organizationId)
  return result.data.data?.item
})

const { result: emailsResult, execute: refreshEmails } = useQuery(
  api.v1.usersEmailAddressesList,
  {
    filterByUserIds: [userId.value],
  }
)

const emails = computed(() => emailsResult.value?.data?.data?.items || [])

const availableRoles = computed(() =>
  userOrg.value?.type === 'admin' ? adminRoles(api) : standardRoles(api)
)

const roleItemsById = Object.fromEntries(
  [...adminRoles(api), ...standardRoles(api)].map((roleItem) => [
    roleItem.id,
    roleItem,
  ])
)

const { result: rolesResult, execute: refreshRoles } = useQuery(
  api.v1.rolesAssignmentsList,
  { filterByUserId: [userId.value] }
)

const roles = computed(
  () => (rolesResult.value?.data?.data?.items as RoleAssignment[]) || []
)

const unassignedRoles = computed(() => {
  if (!user.value) return []
  return availableRoles.value.filter(
    (role) => !roles.value?.find((r) => r?.role === role.id)
  )
})

const saveUserDataLoading = ref(false)
const saveRolesLoading = ref(false)
const saveEmailsLoading = ref(false)

const saveUserData = async (values: User) => {
  saveUserDataLoading.value = true
  try {
    await api.v1.usersUpdate(userId.value, {
      active: values.active as boolean,
      fullName: values.fullName as string,
    })
  } catch (err) {
    console.error(err)
  }
  saveUserDataLoading.value = false
}

const roleName = (roleId?: string): string | undefined => {
  if (!roleId) return ''

  return roleItemsById[roleId].name
}

const isAccessDeclarationRole = (role = '') =>
  ['customer-success', 'technical-support'].includes(role)

const roleFormState = ref<RoleAssignment>({ role: '' })

const transformRoleFormState = () => {
  const { cloned: out } = useCloned(roleFormState)
  if (isAccessDeclarationRole(out.value.role))
    out.value.canUseAccessDeclarations ??= false

  return out.value
}

const clearRoleFormState = () => {
  roleFormState.value = { role: '' }
}
const setRoleFormState = (item: RoleAssignment) => {
  const { cloned } = useCloned(item, { manual: true })
  roleFormState.value = cloned.value
}

const createEmail = async (email: EmailAddress) => {
  if (!email.original) return

  const { data } = await api.v1.usersEmailAddressesCreate({
    userId: userId.value,
    emailAddress: email.original,
  })

  if (email.primary) {
    const emailId = data.data?.item?.id
    if (emailId) {
      await api.v1.usersEmailAddressesPrimaryUpdate(emailId)
    }
  }
}

const handleInsertEmail = async (email: EmailAddress) => {
  saveEmailsLoading.value = true
  await createEmail(email)
  await refreshEmails()
  saveEmailsLoading.value = false
}

const handleUpdateEmail = async (
  newEmail: EmailAddress,
  oldEmail: EmailAddress
) => {
  if (!newEmail.original || !oldEmail.id) return
  saveEmailsLoading.value = true

  // Email changes -> Create new email, maybe update primary, then delete old email
  if (newEmail.original !== oldEmail.original) {
    await createEmail(newEmail)

    await api.v1.usersEmailAddressesDelete(oldEmail.id)
    // Email is the same, but marked primary
  } else if (newEmail.primary && !oldEmail.primary) {
    await api.v1.usersEmailAddressesPrimaryUpdate(oldEmail.id)
  }

  await refreshEmails()
  saveEmailsLoading.value = false
}

const handleRemoveEmail = async (email: EmailAddress) => {
  if (!email.id) return
  saveEmailsLoading.value = true
  await api.v1.usersEmailAddressesDelete(email.id)
  await refreshEmails()
  saveEmailsLoading.value = false
}

const handleInsertRole = async (role: RoleAssignment) => {
  if (!role.role) return
  saveRolesLoading.value = true

  await roleItemsById[role.role].create({
    userId: userId.value,
    canUseAccessDeclarations: role.canUseAccessDeclarations,
  })
  await refreshRoles()
  clearRoleFormState()

  saveRolesLoading.value = false
}
const handleUpdateRole = async (
  newRole: RoleAssignment,
  oldRole: RoleAssignment
) => {
  if (!newRole.role || !oldRole.role) return

  saveRolesLoading.value = true

  if (newRole.role !== oldRole.role) {
    await roleItemsById[newRole.role].create({
      userId: userId.value,
      canUseAccessDeclarations: newRole.canUseAccessDeclarations,
    })

    await roleItemsById[oldRole.role].delete(oldRole.id)
  } else if (
    newRole.canUseAccessDeclarations !== oldRole.canUseAccessDeclarations
  ) {
    const updateFn = roleItemsById[newRole.role].update
    if (updateFn && newRole.id) {
      await updateFn(newRole.id, {
        canUseAccessDeclarations: newRole.canUseAccessDeclarations,
      })
    }
  }

  clearRoleFormState()
  refreshRoles()
  saveRolesLoading.value = false
}

const handleRemoveRole = async (role: RoleAssignment) => {
  if (!role.role) return
  saveRolesLoading.value = true

  await roleItemsById[role.role].delete(role.id)
  await refreshRoles()

  saveRolesLoading.value = false
}
</script>

<template>
  <PageLayout
    v-if="user"
    :title="$t('pages.usersEdit.title')"
    :subtitle="userOrg?.name"
    :breadcrumbs="[{ text: $t('pages.users.title'), to: { name: 'users' } }]"
  >
    <section>
      <header class="pb-5">
        <h2 class="text-base font-semibold">
          {{ $t('pages.usersEdit.userDetailsTitle') }}
        </h2>
      </header>

      <DynamicForm
        :schema="userEditSchema"
        :initial-values="user"
        :submit-text="$t('actions.save')"
        :pending="saveUserDataLoading"
        @submit="saveUserData($event)"
      />
    </section>

    <section>
      <header class="py-5">
        <h2 class="text-base font-semibold">
          {{ $t('pages.usersEdit.emailsTitle') }}
        </h2>
      </header>

      <EditList
        :items="emails"
        item-key="original"
        deletable
        editable
        addable
        class="space-y-2"
        :disable-delete="(item) => emails.length > 1 && !!item.primary"
        @insert="handleInsertEmail"
        @update="handleUpdateEmail"
        @remove="handleRemoveEmail"
      >
        <template #default="{ item }">
          <Panel body-class="flex w-full">
            <span>
              {{ item.original }}
            </span>

            <Badge v-if="item.primary" class="ml-3"> Primary </Badge>
          </Panel>
        </template>

        <template #form="{ submit, cancel, item }">
          <Panel>
            <DynamicForm
              :schema="
                userEmailSchema(
                  emails.filter((e) => e.original !== item?.original)
                )
              "
              :initial-values="defaultEmailAddress({ ...item })"
              :submit-text="$t('actions.submit')"
              :cancel-text="$t('actions.cancel')"
              :pending="saveUserDataLoading"
              :submit-btn-props="{ size: 'sm', outline: true }"
              :cancel-btn-props="{ size: 'sm', outline: true }"
              @submit="submit($event as EmailAddress)"
              @cancel="cancel"
            />
          </Panel>
        </template>
      </EditList>
    </section>

    <section>
      <header class="py-5">
        <h2 class="text-base font-semibold">
          {{ $t('pages.usersEdit.rolesTitle') }}
        </h2>
      </header>

      <EditList
        :items="roles"
        item-key="role"
        class="space-y-2"
        deletable
        editable
        :addable="unassignedRoles.length > 0"
        @edit="setRoleFormState"
        @insert="handleInsertRole"
        @update="handleUpdateRole"
        @remove="handleRemoveRole"
      >
        <template #default="{ item }">
          <Panel body-class="flex w-full">
            <span>
              {{ roleName(item.role) }}
            </span>

            <Badge v-if="item.canUseAccessDeclarations" class="ml-3">
              {{ $t('forms.rolePicker.accessDeclarationsBadge') }}
            </Badge>
          </Panel>
        </template>

        <template #form="{ submit: submitRole, cancel }">
          <Panel body-class="space-y-2">
            <label for="role-form-role-select">
              {{ $t('forms.rolePicker.roleSelect') }}
            </label>
            <select id="role-form-role-select" v-model="roleFormState.role">
              <option value="" disabled selected>
                {{ $t('forms.rolePicker.rolePlaceholder') }}
              </option>
              <option
                v-for="role in [
                  ...unassignedRoles,
                  ...(roleFormState.role
                    ? [roleItemsById[roleFormState.role]]
                    : []),
                ]"
                :key="role.id"
                :value="role.id"
              >
                {{ role.name }}
              </option>
            </select>

            <div v-if="isAccessDeclarationRole(roleFormState.role)">
              <input
                id="role-form-access-declarations"
                v-model="roleFormState.canUseAccessDeclarations"
                type="checkbox"
                class="mr-2"
              />

              <label for="role-form-access-declarations">
                {{ $t('forms.rolePicker.accessDeclarationsCheck') }}
              </label>
            </div>

            <footer class="!mt-5 space-x-2">
              <Btn
                variant="primary"
                outline
                size="sm"
                @click="submitRole(transformRoleFormState())"
              >
                {{ $t('actions.submit') }}
              </Btn>

              <Btn variant="secondary" outline size="sm" @click="cancel">
                {{ $t('actions.cancel') }}
              </Btn>
            </footer>
          </Panel>
        </template>
      </EditList>
    </section>
  </PageLayout>
</template>
