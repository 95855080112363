<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { DynamicForm, licenseAgreementSchema } from '@/forms'
import { LicenseAgreement } from '@/models'
import { useApi, useQuery } from '@/composables'
import { MenuItemButton } from '@/components'
import { formatDateTime } from '@/utils'

const router = useRouter()
const route = useRoute()

const api = useApi()

const { transformed: agreement, execute: refreshAgreement } = useQuery(
  api.v1.licenseAgreementsDetail,
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id,
  {
    transform: (data) => data?.data?.data?.item || null,
  }
)

const submit = async (values: Partial<LicenseAgreement>) => {
  if (!agreement.value || !values.body || !values.type) return

  await api.v1.licenseAgreementsUpdate(agreement.value.id, {
    mediaType: 'text/plain',
    body: values.body,
    type: values.type,
  })

  router.push({
    name: 'license-agreements',
  })
}

const publishAgreement = async () => {
  if (!agreement.value) return
  await api.v1.licenseAgreementsCurrentUpdate(agreement.value.id)
  await refreshAgreement()
}
</script>

<template>
  <PageLayout
    v-if="agreement"
    :title="$t(`pages.licenseAgreementsEdit.${agreement.type}Type`)"
    :subtitle="
      agreement.publishedAt
        ? $t('pages.licenseAgreementsEdit.publishedAt', {
            date: formatDateTime(agreement.publishedAt),
          })
        : $t('pages.licenseAgreementsEdit.createdAt', {
            date: formatDateTime(agreement.createdAt),
          })
    "
    :breadcrumbs="[
      {
        text: $t('pages.licenseAgreements.title'),
        to: { name: 'license-agreements', query: { type: agreement?.type } },
      },
    ]"
  >
    <template v-if="!agreement?.publishedAt" #menu>
      <MenuItemButton @click="publishAgreement">
        {{ $t('actions.publish') }}
      </MenuItemButton>
    </template>

    <div v-if="agreement">
      <DynamicForm
        v-if="!agreement.publishedAt"
        :schema="licenseAgreementSchema"
        :initial-values="agreement"
        :submit-text="$t('actions.save')"
        @submit="submit($event as LicenseAgreement)"
      />

      <div v-else class="whitespace-pre-wrap">
        {{ agreement.body }}
      </div>
    </div>
  </PageLayout>
</template>
