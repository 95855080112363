<script setup lang="ts">
import { useRouter } from 'vue-router'
import { LicenseAgreement } from '@/models'
import { DynamicForm, licenseAgreementSchema } from '@/forms'
import { useApi } from '@/composables'

const router = useRouter()
const api = useApi()

const submit = async (values: Partial<LicenseAgreement>) => {
  try {
    if (!values.body || !values.type) return

    const response = await api.v1.licenseAgreementsCreate({
      mediaType: 'text/plain',
      body: values.body,
      type: values.type,
    })

    router.push({
      name: 'license-agreements-edit',
      params: { id: response.data.data?.item?.id },
    })
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <PageLayout
    :title="$t('pages.licenseAgreementsCreate.title')"
    :breadcrumbs="[
      {
        text: $t('pages.licenseAgreements.title'),
        to: { name: 'license-agreements' },
      },
    ]"
  >
    <DynamicForm
      :schema="licenseAgreementSchema"
      :submit-text="$t('actions.save')"
      @submit="submit($event)"
    />
  </PageLayout>
</template>
