<script setup lang="ts">
import { Btn } from '@/components'

const throwError = () => {
  throw new Error('This is an error thrown by test bench')
}
</script>

<template>
  <div>
    <header class="mb-8">
      <h1>Test Bench</h1>
    </header>

    <div>
      <Btn @click="throwError">Throw error</Btn>
    </div>
  </div>
</template>
