<script setup lang="ts">
import { useAuthStore } from '@/store'
import { useDark, useToggle } from '@vueuse/core'
import {
  Bars3Icon,
  RectangleGroupIcon,
  MoonIcon,
  SunIcon,
  UserCircleIcon,
  DocumentTextIcon,
} from '@heroicons/vue/24/solid'
import { useOutdated } from '@/composables'
import { IdleMonitor, Logo, Sidebar, SidebarItem } from '@/components'

const authStore = useAuthStore()

const isDark = useDark()
const toggleDark = useToggle(isDark)

const rtf = new Intl.RelativeTimeFormat('en', { style: 'long' })

const idleTimeout = parseInt(import.meta.env.VITE_IDLE_MS ?? 5 * 60 * 1000)
const cutoffTimeout = parseInt(
  import.meta.env.VITE_IDLE_CUTOFF_MS ?? 5 * 60 * 1000
)

const isOutdated = useOutdated(1000 * 60 * 5) // 5 minutes

const refreshPage = () => {
  window.location.reload()
}
</script>

<template>
  <div>
    <Sidebar sidebar-class="bg-gray-900">
      <template #aside>
        <div class="flex h-full flex-col justify-between">
          <nav>
            <router-link
              :to="{ name: 'home' }"
              class="block border-b border-gray-800 p-5 text-2xl font-thin text-white hover:bg-white/5 hover:no-underline focus:bg-white/5 focus:outline-none"
            >
              <Logo />
            </router-link>
            <div v-if="authStore.self">
              <SidebarItem
                :to="{ name: 'organizations' }"
                :icon="RectangleGroupIcon"
                >{{ $t('nav.organizations') }}</SidebarItem
              >

              <SidebarItem :to="{ name: 'users' }" :icon="RectangleGroupIcon">{{
                $t('nav.users')
              }}</SidebarItem>

              <SidebarItem
                :to="{ name: 'access-declarations' }"
                :icon="RectangleGroupIcon"
              >
                {{ $t('nav.accessDeclarations') }}
              </SidebarItem>

              <SidebarItem
                v-if="authStore.hasPermission('license-agreements:read:all')"
                :to="{ name: 'license-agreements' }"
                :icon="DocumentTextIcon"
                >{{ $t('nav.licenseAgreements') }}</SidebarItem
              >
            </div>
          </nav>

          <nav>
            <SidebarItem
              :icon="isDark ? SunIcon : MoonIcon"
              @click="toggleDark()"
              >{{ isDark ? 'Light' : 'Dark' }}</SidebarItem
            >
            <SidebarItem
              :to="{ name: !authStore.self ? 'login' : 'logout' }"
              :icon="UserCircleIcon"
              >{{
                !authStore.self ? $t('nav.login') : $t('nav.logout')
              }}</SidebarItem
            >
          </nav>
        </div>
      </template>

      <template #default="{ state, toggle }">
        <header
          v-if="state !== 'pinned'"
          class="flex border-b border-gray-800 bg-gray-900"
        >
          <router-link
            :to="{ name: 'home' }"
            class="p-5 text-2xl font-thin text-white hover:no-underline"
          >
            <Logo />
          </router-link>

          <nav class="ml-auto">
            <button class="p-5 text-white" @click="toggle">
              <Bars3Icon class="h-6 w-6" />
            </button>
          </nav>
        </header>

        <main class="container mx-auto max-w-screen-xl px-5 py-10 lg:px-10">
          <suspense>
            <router-view v-slot="{ Component, route: pageRoute }">
              <component :is="Component" :key="pageRoute.path" />
            </router-view>
          </suspense>
        </main>
      </template>
    </Sidebar>
  </div>

  <IdleMonitor
    v-slot="{ idleFor, remaining }"
    :idle-timeout="idleTimeout"
    :cutoff-timeout="cutoffTimeout"
    :disabled="!authStore.self"
    @cutoff="authStore.logout()"
  >
    {{
      $t('messages.idle', {
        time: rtf.format(-1 * Math.floor(idleFor / 1000 / 60), 'minutes'),
        cutoff: rtf.format(Math.ceil(remaining / 1000 / 60), 'minutes'),
      })
    }}
  </IdleMonitor>

  <button
    v-if="isOutdated"
    class="fixed inset-x-0 top-0 z-50 bg-info-500 p-3 text-center text-white shadow-lg transition-colors hover:bg-info-600"
    @click="refreshPage"
  >
    {{ $t('messages.outdated') }}
  </button>

  <div id="modals" />
  <div id="menus" />
</template>
