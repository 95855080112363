import { Ref, watch } from 'vue'
import { useRouteQuery } from '@vueuse/router'

export const useSearchQuery = (
  name = 'term',
  initial: string | null = null
) => {
  const term: Ref<string | null> = useRouteQuery(name, initial, {
    // use the first value if it's an array
    transform: (val: string | string[] | null) =>
      (Array.isArray(val) ? val[0] : val ?? '').trim().toLowerCase(),
  })

  const clearTerm = () => {
    term.value = null
  }

  // convert empty term to null to remove from query
  watch(
    () => term.value,
    (value) => {
      if (value === '') {
        term.value = null
      }
    },
    { immediate: true }
  )

  return {
    term,
    clearTerm,
  }
}
