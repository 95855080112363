<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { DynamicForm, organizationEditSchema } from '@/forms'
import { Organization } from '@/models'
import { useApi, useQuery } from '@/composables'
import { computed, ref, watchEffect } from 'vue'
import { MenuItemButton, ModalDialog } from '@/components'
import { useAuthStore } from '@/store'

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()

const api = useApi()

const { result, error } = useQuery(
  api.v1.organizationsDetail,
  Array.isArray(route.params.id) ? route.params.id[0] : route.params.id
)

const { result: languageRes } = useQuery(api.v1.languagesList, {})
const languageOptions = computed(
  () =>
    languageRes.value?.data?.data?.items?.map((i) => ({
      label: i.name?.[0].value || '',
      value: i.code || '',
    })) || []
)

const org = computed(() => result.value?.data?.data?.item)
watchEffect(() => error.value && router.replace({ name: '404' }))

const submit = async (values: Organization) => {
  if (!org.value?.id) return
  try {
    await api.v1.organizationsUpdate(org.value.id, {
      name: values.name as string,
      shortName: values.shortName as string,
      defaultLanguageCode: values.defaultLanguageCode,
    })

    if (org.value.active !== values.active) {
      await api.v1.organizationsActiveUpdate(org.value.id, {
        active: Boolean(values.active),
      })
    }

    if (org.value.type !== values.type && values.type) {
      await api.v1.organizationsTypeUpdate(org.value.id, { type: values.type })
    }

    router.push({ name: 'organizations' })
  } catch (err) {
    // TODO: Global toast/flash component to display errors?
    console.error(err)
  }
}

const accessDeclarationFormVisible = ref(false)
const declarationReason = ref('')
const submitAccessDeclarationForm = async () => {
  if (!org.value?.id) return

  await api.v1.declarationsAccessCreate({
    accessOrganizationId: org.value.id,
    reason: declarationReason.value,
  })
}
</script>

<template>
  <PageLayout
    :title="$t('pages.organizationsEdit.title')"
    :breadcrumbs="[
      { text: $t('pages.organizations.title'), to: { name: 'organizations' } },
    ]"
  >
    <template
      v-if="authStore.hasPermission('access-declarations:create:all')"
      #menu
    >
      <MenuItemButton @click="accessDeclarationFormVisible = true">
        {{ $t('actions.createModel', [$t('models.accessDeclaration')]) }}
      </MenuItemButton>
    </template>

    <DynamicForm
      v-if="org"
      :schema="organizationEditSchema({ languageOptions })"
      :initial-values="org"
      :submit-text="$t('actions.save')"
      @submit="submit($event as Organization)"
    />

    <ModalDialog
      :title="$t('forms.accessDeclarations.title', [org?.name])"
      :open="accessDeclarationFormVisible"
      cancelable
      closable
      confirmable
      @close="accessDeclarationFormVisible = false"
      @confirm="submitAccessDeclarationForm"
    >
      <label for="declaration-reason">
        {{ $t('forms.accessDeclarations.reason') }}
      </label>
      <input id="declaration-reason" v-model="declarationReason" type="text" />
    </ModalDialog>
  </PageLayout>
</template>
