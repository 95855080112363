/* eslint-disable */
/* tslint:disable */
import type { DateLike } from '@@/types'

export interface Error {
  status?: 'error'
  code?: number
  message?: string
  meta?: object
}

export interface Fail {
  status?: 'fail'
  failure?: string
  code?: number
  data?: object
}

export interface Settings {
  /**
   * @deprecated
   * @min 1
   */
  playMinutes?: number
  /** @min 1 */
  playSeconds: number
  /** @min 1 */
  practiceCount: number
  automaticallyAdvance: boolean
  provideFeedback: boolean
}

export interface EmailPasswordCredentials {
  organizationShortName: string
  /** @format email */
  emailAddress: string
  /** @format password */
  password: string
}

export interface PasscodeCredentials {
  organizationShortName: string
  /** @format password */
  passcode: string
}

export interface SelfDetails {
  organization: Organization
  user: User
  settings: Settings
  roles: RolesArray
  pendingLicenseAgreements: AcceptedLicenseAgreement[]
  permissions: string[]
  scopeOrder: string[]
}

export interface AuthorizationDetails {
  /** @format jwt */
  accessToken: string
  /** @format jwt */
  refreshToken: string
  organization: Organization
  user: User
  settings: Settings
  roles: RolesArray
  pendingLicenseAgreements: AcceptedLicenseAgreement[]
  permissions: string[]
  scopeOrder: string[]
}

export interface AccessDeclaration {
  /** @format uuid */
  id: string
  /** @format uuid */
  userId: string
  userFullName: string
  /** @format uuid */
  accessOrganizationId: string
  accessOrganizationName: string
  reason: string
  /** @format date-time */
  expiresAt: DateLike
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface TargetPhonemeSpecification {
  /** @format uuid */
  phonemeId: string
  initial: boolean
  medial: boolean
  final: boolean
}

export interface TargetPhoneme {
  /** @format uuid */
  phonemeId: string
  phonemeTitle: string
  initial: boolean
  medial: boolean
  final: boolean
}

export interface GoalItem {
  /** @format uuid */
  id: string
  /** @format uuid */
  utteranceId: string
  utteranceType: UtteranceType
  utteranceText: string
  targetPhonemes: TargetPhoneme[]
}

export type GoalState = 'draft' | 'active' | 'inactive' | 'archived'

export interface Goal {
  /** @format uuid */
  id: string
  /** @format uuid */
  userId: string
  /** @example "en-US" */
  languageCode: string
  title: string
  imitation: boolean
  state: GoalState
  items: GoalItem[]
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface GoalSummary {
  /** @format uuid */
  id: string
  /** @format uuid */
  userId: string
  /** @example "en-US" */
  languageCode: string
  title: string
  imitation: boolean
  state: GoalState
  itemCount: number
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface PracticeItem {
  /** @format uuid */
  id: string
  /** @format uuid */
  utteranceId: string
  utteranceType: UtteranceType
  utteranceText: string
  imitation: boolean
  /** @format date-time */
  expiresAt: DateLike
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface SummaryActivityGoalMetrics {
  /** @min 0 */
  total: number
  /**
   * @min 0
   * @max 100
   */
  percentage: number
}

export interface SummaryRatingGoalMetrics {
  /** @min 0 */
  total: number
  /**
   * @min 0
   * @max 100
   */
  percentage: number
  /** @format date-time */
  firstAt: DateLike | null
  /** @format date-time */
  lastAt: DateLike | null
}

export interface SummaryGoalMetrics {
  recordings: {
    /** @min 0 */
    total: number
    /** @format date-time */
    firstAt: DateLike | null
    /** @format date-time */
    lastAt: DateLike | null
  }
  ratings: {
    good: SummaryRatingGoalMetrics
    fair: SummaryRatingGoalMetrics
    poor: SummaryRatingGoalMetrics
    unrated: SummaryRatingGoalMetrics
  }
  activity: {
    daily: SummaryActivityGoalMetrics
    weekly: SummaryActivityGoalMetrics
    monthly: SummaryActivityGoalMetrics
    yearly: SummaryActivityGoalMetrics
  }
}

export type GoalMetricIntervals = 'daily' | 'weekly' | 'monthly' | 'yearly'

export interface IntervalRatingGoalMetrics {
  /** @min 0 */
  total: number
  /**
   * @min 0
   * @max 100
   */
  percentage: number
}

export interface IntervalGoalMetrics {
  /** @format date-time */
  startsAt: DateLike
  recordings: {
    /** @min 0 */
    total: number
  }
  ratings: {
    good: IntervalRatingGoalMetrics
    fair: IntervalRatingGoalMetrics
    poor: IntervalRatingGoalMetrics
    unrated: IntervalRatingGoalMetrics
  }
}

export interface LocalizedString {
  /** @example "en-US" */
  code?: string
  value?: string
}

export interface Language {
  /** @example "en-US" */
  code?: string
  name?: LocalizedString[]
}

/** @example {"id":"7be15a7e-05c1-46e4-a7d3-6d8b7dabbbe9","index":0,"title":"Vocalic Rs","children":[{"id":"0e6b111b-c3ac-467e-bc90-f68b64b29160","parentId":"7be15a7e-05c1-46e4-a7d3-6d8b7dabbbe9","index":0,"title":"ER /ɝ, ɚ/","example":"earth","codes":["ER","AHR"]}]} */
export interface Phoneme {
  /** @format uuid */
  id: string
  /** @format uuid */
  parentId?: string
  /** @min 0 */
  index?: number
  title: string
  example?: string
  codes?: string[]
  children?: Phoneme[]
}

export interface PhonemeQuery {
  /** @format uuid */
  phonemeId: string
  initial: boolean
  medial: boolean
  final: boolean
  occursOnce: boolean
  occursMany: boolean
  cluster?: 'include' | 'exclude' | 'only'
}

export type UtteranceType = 'word' | 'phrase' | 'sentence'

export interface UtteranceQuery {
  /** @minItems 1 */
  includePhonemeQueries: PhonemeQuery[]
  excludePhonemeIds: string[]
  /** @minItems 1 */
  utteranceTypes: UtteranceType[]
  search: string | null
  /** @min 1 */
  syllablesMin: number | null
  /** @min 1 */
  syllablesMax: number | null
  /** @min 1 */
  wordsMin: number | null
  /** @min 1 */
  wordsMax: number | null
  /** @min 0 */
  pageIndex: number
  /** @min 1 */
  pageSize: number
}

export interface Utterance {
  /** @format uuid */
  id: string
  type: UtteranceType
  text: string
  /** @min 1 */
  wordCount: number
  /** @min 1 */
  syllableCount: number
}

export interface UtteranceMediaLocation {
  method: 'GET'
  /**
   * The url of the media
   * @format uri
   */
  url: string
  /**
   * The expiration date of the url
   * @format date-time
   */
  expiresAt: DateLike
}

export interface LicenseAgreementAccept {
  /** @format uuid */
  id: string
  /** @format uuid */
  licenseAgreementId: string
  /** @format uuid */
  organizationId: string
  organizationName: string
  /** @format uuid */
  userId: string
  userFullName: string
  /** @format date-time */
  createdAt: DateLike
}

export type AcceptedLicenseAgreement = LicenseAgreement & {
  accepts?: LicenseAgreementAccept[]
}

export type LicenseAgreementType = 'provider' | 'enduser'

export type LicenseAgreementMediaType = 'text/plain'

export interface LicenseAgreement {
  /** @format uuid */
  id: string
  type: LicenseAgreementType
  current: boolean
  mediaType: LicenseAgreementMediaType
  body: string
  /** @format date-time */
  publishedAt?: DateLike
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export type OrganizationType = 'standard' | 'admin' | 'demo'

export interface Organization {
  /** @format uuid */
  id?: string
  active?: boolean
  name?: string
  shortName?: string
  defaultLanguageCode?: string | null
  type?: OrganizationType
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface Patient {
  /** @format uuid */
  userId: string
  fullName: string
  active: boolean
  /** @format date-time */
  activeAt?: DateLike | null
  /** @min 1900 */
  birthYear: number | null
  /**
   * @min 1
   * @max 12
   */
  birthMonth: number | null
  /** @min 0 */
  ageYears: number | null
  /**
   * @min 0
   * @max 11
   */
  ageMonths: number | null
  /**
   * Use `languages` instead
   * @deprecated
   */
  defaultLanguage?: Language | null
  language: Language | null
  roles?: RolesArray
}

/** @example {"mediaType":"video/mp4","fileType":"mp4","contentLength":1024} */
export interface RecordingMediaDetail {
  mediaType: string
  fileType: string
  /** @min 1 */
  contentLength: number
}

/** @example {"id":"123e4567-e89b-12d3-a456-426614174000","mediaType":"video/mp4","fileType":"mp4","contentLength":1024} */
export interface RecordingMedia {
  /** @format uuid */
  id: string
  mediaType: string
  fileType: string
  /** @min 1 */
  contentLength: number
}

export interface Recording {
  /** @format uuid */
  id: string
  /** @format uuid */
  userId: string
  userFullName: string
  /** @format uuid */
  goalId: string
  goalTitle: string
  /** @format uuid */
  utteranceId: string
  utteranceType: UtteranceType
  utteranceText: string
  imitation: boolean
  targetPhonemes: TargetPhoneme[]
  latestRating: Rating | null
  media: RecordingMedia[]
  /** @min 0 */
  seconds: number
  /** @format date-time */
  recordedAt: DateLike
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface RecordingMediaLocation {
  method: 'GET' | 'PUT'
  /**
   * The url of the media
   * @format uri
   */
  url: string
  /**
   * The expiration date of the url
   * @format date-time
   */
  expiresAt: DateLike
}

export type RatingValue = 'good' | 'fair' | 'poor' | 'ignored'

export type RatingSource = 'platform' | 'care-provider'

export interface Rating {
  /** @format uuid */
  id: string
  /** @format uuid */
  recordingId: string
  /** @format uuid */
  userId: string | null
  userFullName: string | null
  latest: boolean
  value: RatingValue
  source: RatingSource
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface AssistantToSpeechLanguagePathologistRelationship {
  /** @format uuid */
  id?: string
  type?: 'assistant-to-speech-language-pathologist'
  /** @format uuid */
  fromUserId?: string
  /** @format uuid */
  toUserId?: string
  toUserFullName?: string
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  assistantRoleAssignmentId?: string
  /** @format uuid */
  speechLanguagePathologistRoleAssignmentId?: string
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export type RelationshipTypesArray = RelationshipTypeEnum[]

export type RelationshipTypeEnum =
  | 'assistant-to-speech-language-pathologist'
  | 'patient-to-speech-language-pathologist'
  | 'patient-to-caregiver'

export interface PatientToCaregiverRelationship {
  /** @format uuid */
  id?: string
  type?: 'patient-to-caregiver'
  /** @format uuid */
  fromUserId?: string
  /** @format uuid */
  toUserId?: string
  toUserFullName?: string
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  patientRoleAssignmentId?: string
  /** @format uuid */
  caregiverRoleAssignmentId?: string
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface PatientToSpeechLanguagePathologistRelationship {
  /** @format uuid */
  id?: string
  type?: 'patient-to-speech-language-pathologist'
  /** @format uuid */
  fromUserId?: string
  /** @format uuid */
  toUserId?: string
  toUserFullName?: string
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  patientRoleAssignmentId?: string
  /** @format uuid */
  speechLanguagePathologistRoleAssignmentId?: string
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface ComplianceRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'compliance'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface CustomerSuccessRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'customer-success'
  canUseAccessDeclarations?: boolean
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface TechnicalSupportRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'technical-support'
  canUseAccessDeclarations?: boolean
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface AssistantRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'assistant'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface AuditRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'audit'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface BillingRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'billing'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface DirectorRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'director'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface OwnerRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'owner'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface SpeechLanguagePathologistRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'speech-language-pathologist'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface CaregiverRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'caregiver'
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export interface PatientRoleAssignment {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  role?: 'patient'
  active?: boolean
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export type RolesArray = RoleEnum[]

export type RoleEnum =
  | 'assistant'
  | 'audit'
  | 'billing'
  | 'patient'
  | 'compliance'
  | 'customer-success'
  | 'director'
  | 'owner'
  | 'caregiver'
  | 'speech-language-pathologist'
  | 'technical-support'

export interface User {
  /** @format uuid */
  id?: string
  /** @format uuid */
  organizationId?: string
  organizationName?: string
  active?: boolean
  fullName?: string
  /** @format email */
  primaryEmailAddress?: string | null
  roles?: RolesArray
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
  /** @format date-time */
  activeAt?: DateLike | null
}

export interface EmailAddress {
  /** @format uuid */
  id?: string
  /** @format uuid */
  userId?: string
  /** @format uuid */
  organizationId?: string
  /** @format email */
  original?: string
  /** @format email */
  normalized?: string
  primary?: boolean
  bounced?: boolean
  /** @format date-time */
  createdAt?: DateLike
  /** @format date-time */
  updatedAt?: DateLike
}

export type PasscodeType = 'short'

export interface Passcode {
  /** @format uuid */
  id: string
  /** @format uuid */
  userId: string
  /** @format uuid */
  organizationId: string
  type: PasscodeType
  /** @format password */
  text: string
  /** @format date-time */
  expiresAt: DateLike
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface PatientRecord {
  /** @format uuid */
  id: string
  /** @format uuid */
  userId: string
  type: 'patient'
  latest: boolean
  /**
   * Use 'languageCode' instead
   * @deprecated
   */
  defaultLanguageCode?: string | null
  languageCode: string | null
  /** @min 1 */
  birthMonth: number | null
  /** @min 1900 */
  birthYear: number | null
  /**
   * Use 'playSeconds' instead
   * @deprecated
   * @min 1
   */
  playMinutes?: number | null
  /** @min 1 */
  playSeconds: number | null
  /** @min 1 */
  practiceCount: number | null
  automaticallyAdvance: boolean | null
  provideFeedback: boolean | null
  /** @format date-time */
  createdAt: DateLike
  /** @format date-time */
  updatedAt: DateLike
}

export interface PatientRecordDefaults {
  language: Language
  /** @min 1 */
  playSeconds: number
  /** @min 1 */
  practiceCount: number
  automaticallyAdvance: boolean
  provideFeedback: boolean
}

export interface DeclarationsAccessListParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
}

export interface GoalsListParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /** The user (patient) related to the goal */
  userId: string
  /** The state of the goal */
  state?: GoalState | GoalState[]
}

export interface GoalsMetricsDetailParams {
  /** The timezone to use for intervals (e.g. "America/New_York") */
  timezone: string
  /**
   * The goal id
   * @format uuid
   */
  id: string
}

export interface GoalsMetricsIntervalsDetailParams {
  /** The interval to group the metrics by */
  interval: GoalMetricIntervals
  /** The timezone to use for intervals (e.g. "America/New_York") */
  timezone: string
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /** The goal id */
  id: string
}

export interface LanguagesListParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
}

export interface LicenseAgreementsAcceptsListParams {
  /**
   * Filter by organization ID
   * @format uuid
   */
  filterByOrganizationId: string
  /**
   * Filter by user ID
   * @format uuid
   */
  filterByUserId?: string
  /** Filter by agreement type */
  filterByType?: LicenseAgreementType
  /** Filter on pending acceptance */
  filterByPending?: boolean
}

export interface LicenseAgreementsListParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /** Filter by agreement type */
  filterByType?: LicenseAgreementType
  /** Filter on `current` state */
  filterByCurrent?: boolean
  /** Filter by published state */
  filterByPublished?: boolean
}

export interface OrganizationsListParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /** An optional search query */
  search?: string
}

export interface PatientsListParams {
  /** Only include active or inactive patients (defaults to all) */
  active?: boolean
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /** An optional search query */
  search?: string
}

export interface RecordingsListParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /**
   * The user id to filter by
   * @format uuid
   */
  filterByUserId?: string
  /**
   * The goal id to filter by
   * @format uuid
   */
  filterByGoalId?: string
  /** Filter for recordings that are rated by the platform or by a care provider */
  filterByLatestRatingSource?: 'platform' | 'care-provider'
  /** Filter for recordings without a rating, or a specific rating value */
  filterByLatestRatingValue?: 'none' | 'good' | 'fair' | 'poor' | 'ignored'
}

export interface RecordingsRatingsListParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /** The recording id of the ratings */
  recordingId: string
}

export interface RelationshipsListParams {
  /** Only include items belonging to the given organization */
  filterByOrganizationId?: string[]
  /** Only include items of the given type */
  filterByRelationshipType?: RelationshipTypesArray
  /** Only include relationships from the given user */
  filterByFromUserId?: string[]
  /** Only include relationships to the given user */
  filterByToUserId?: string[]
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
}

export interface RolesAssignmentsListParams {
  /** Only include items belonging to the given organization */
  filterByOrganizationId?: string[]
  /** Only include items of the given role */
  filterByRole?: RolesArray
  /** Only include items belonging to the given user */
  filterByUserId?: string[]
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
}

export interface UsersListParams {
  /** Only include items belonging to the given organizations */
  filterByOrganizationIds?: string[]
  /** Only include users having the given roles */
  filterByRoles?: RolesArray
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /** An optional search query */
  search?: string
}

export interface UsersEmailAddressesListParams {
  /** Only include items belonging to the given organizations */
  filterByOrganizationIds?: string[]
  /** Only include items belonging to the given users */
  filterByUserIds?: string[]
  /** An optional search query */
  search?: string
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
}

export interface UsersRecordsPatientVersionsDetailParams {
  /**
   * The index of the page to return
   * @min 0
   */
  pageIndex?: number
  /**
   * The number of items per page
   * @min 1
   */
  pageSize?: number
  /**
   * The ID of the user
   * @format uuid
   */
  id: string
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title SpeakSuite API
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  v1 = {
    /**
     * @description Accepts credentials and returns access and refresh tokens. The credentials can be either an email address and password or a passcode.
     *
     * @tags Authentication
     * @name AuthLoginCreate
     * @summary Login
     * @request POST:/v1/auth/login
     */
    authLoginCreate: (data: EmailPasswordCredentials | PasscodeCredentials, params: RequestParams = {}) =>
      this.request<
        {
          code: 200
          status: 'success'
          data: AuthorizationDetails
        },
        Fail | Error
      >({
        path: `/v1/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Accepts a refresh token and returns new access and refresh tokens.
     *
     * @tags Authentication
     * @name AuthRefreshCreate
     * @summary Refresh
     * @request POST:/v1/auth/refresh
     */
    authRefreshCreate: (
      data: {
        /** @format jwt */
        refreshToken: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          code: 200
          status: 'success'
          data: AuthorizationDetails
        },
        Fail | Error
      >({
        path: `/v1/auth/refresh`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a similar details as login, but without exchanging credentials for tokens.
     *
     * @tags Authentication
     * @name AuthSelfList
     * @summary Read user details
     * @request GET:/v1/auth/self
     * @secure
     */
    authSelfList: (params: RequestParams = {}) =>
      this.request<
        {
          code: 200
          status: 'success'
          data: SelfDetails
        },
        Error
      >({
        path: `/v1/auth/self`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Takes a SAML response and returns a new access token and refresh token.
     *
     * @tags Authentication
     * @name AuthSamlCallbackCreate
     * @summary SAML Callback
     * @request POST:/v1/auth/saml/callback
     */
    authSamlCallbackCreate: (
      data: {
        /** Base64 encoded XML */
        SAMLResponse: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @format jwt */
          accessToken?: string
          /** @format jwt */
          refreshToken?: string
        },
        Fail | Error
      >({
        path: `/v1/auth/saml/callback`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * @description Declarations are required for Protected Health Information Specialists (PHIS) to troubleshoot or otherwise assist customers while interacting with Protected Health Information (PHI). An access declaration allows a user with the PHIS role to access a specific organization's PHI for a limited time. Users with the PHIS role are required to provide a reason for the access declaration when it is created.
     *
     * @tags Access Declarations
     * @name DeclarationsAccessList
     * @summary List access delarations
     * @request GET:/v1/declarations/access
     * @secure
     */
    declarationsAccessList: (query: DeclarationsAccessListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: AccessDeclaration[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/declarations/access`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Declarations are required for Protected Health Information Specialists (PHIS) to troubleshoot or otherwise assist customers while interacting with Protected Health Information (PHI). An access declaration allows a user with the PHIS role to access a specific organization's PHI for a limited time. Users with the PHIS role are required to provide a reason for the access declaration when it is created.
     *
     * @tags Access Declarations
     * @name DeclarationsAccessCreate
     * @summary Create an access delaration
     * @request POST:/v1/declarations/access
     * @secure
     */
    declarationsAccessCreate: (
      data: {
        /** @format uuid */
        accessOrganizationId: string
        reason: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AccessDeclaration
          }
        },
        Fail | Error
      >({
        path: `/v1/declarations/access`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Declarations are required for Protected Health Information Specialists (PHIS) to troubleshoot or otherwise assist customers while interacting with Protected Health Information (PHI). An access declaration allows a user with the PHIS role to access a specific organization's PHI for a limited time. Users with the PHIS role are required to provide a reason for the access declaration when it is created.
     *
     * @tags Access Declarations
     * @name DeclarationsAccessDetail
     * @summary Read an access delaration
     * @request GET:/v1/declarations/access/{id}
     * @secure
     */
    declarationsAccessDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AccessDeclaration
          }
        },
        Fail | Error
      >({
        path: `/v1/declarations/access/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsList
     * @summary List goals
     * @request GET:/v1/goals
     * @secure
     */
    goalsList: (query: GoalsListParams, params: RequestParams = {}) =>
      this.request<
        {
          state?: 'success'
          data: {
            items: GoalSummary[]
          }
          meta: {
            /** @min 0 */
            pageIndex: number
            /** @min 1 */
            totalPages: number
            /** @min 1 */
            pageSize: number
            /** @min 0 */
            totalItems: number
          }
        },
        Fail | Error
      >({
        path: `/v1/goals`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsCreate
     * @summary Create a goal
     * @request POST:/v1/goals
     * @secure
     */
    goalsCreate: (
      data: {
        /** @format uuid */
        userId: string
        /** @example "en-US" */
        languageCode: string
        title: string
        imitation: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsDetail
     * @summary Read a goal
     * @request GET:/v1/goals/{id}
     * @secure
     */
    goalsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsUpdate
     * @summary Update a goal
     * @request PUT:/v1/goals/{id}
     * @secure
     */
    goalsUpdate: (
      id: string,
      data: {
        imitation: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsDelete
     * @summary Delete a goal
     * @request DELETE:/v1/goals/{id}
     * @secure
     */
    goalsDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          state?: 'success'
          data?: object | null
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsDuplicateCreate
     * @summary Duplicate a goal
     * @request POST:/v1/goals/{id}/duplicate
     * @secure
     */
    goalsDuplicateCreate: (id: string, data: object, params: RequestParams = {}) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/duplicate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsStateUpdate
     * @summary Update a goal's state
     * @request PUT:/v1/goals/{id}/state
     * @secure
     */
    goalsStateUpdate: (
      id: string,
      data: {
        state: GoalState
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/state`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsTitleUpdate
     * @summary Update a goal's title
     * @request PUT:/v1/goals/{id}/title
     * @secure
     */
    goalsTitleUpdate: (
      id: string,
      data: {
        title: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/title`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsItemsCreate
     * @summary Create goal items
     * @request POST:/v1/goals/{id}/items
     * @secure
     */
    goalsItemsCreate: (
      id: string,
      data: {
        /** @minItems 1 */
        utteranceIds: string[]
        /** @minItems 1 */
        targetPhonemeSpecifications: TargetPhonemeSpecification[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/items`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsItemsDelete
     * @summary Delete goal items
     * @request DELETE:/v1/goals/{id}/items
     * @secure
     */
    goalsItemsDelete: (
      id: string,
      data: {
        /** @minItems 1 */
        itemIds: string[]
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/items`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsItemsDelete2
     * @summary Delete a goal item
     * @request DELETE:/v1/goals/{id}/items/{itemId}
     * @originalName goalsItemsDelete
     * @duplicate
     * @secure
     */
    goalsItemsDelete2: (id: string, itemId: string, params: RequestParams = {}) =>
      this.request<
        {
          state?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/items/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsItemsPriorityUpdate
     * @summary Update a goal item's priority
     * @request PUT:/v1/goals/{id}/items/{itemId}/priority
     * @secure
     */
    goalsItemsPriorityUpdate: (
      id: string,
      itemId: string,
      data: {
        /** @min 0 */
        priority: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data: {
            item: Goal
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/items/${itemId}/priority`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsItemsPracticeCreate
     * @summary Create the next practice items for the patient
     * @request POST:/v1/goals/items/practice
     * @secure
     */
    goalsItemsPracticeCreate: (
      data: {
        /**
         * @format integer
         * @min 1
         */
        count: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data: {
            items: PracticeItem[]
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/items/practice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Goals
     * @name GoalsMetricsDetail
     * @summary Read summary goal metrics
     * @request GET:/v1/goals/{id}/metrics
     * @secure
     */
    goalsMetricsDetail: ({ id, ...query }: GoalsMetricsDetailParams, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            item: SummaryGoalMetrics
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/metrics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns a list of goal metric intervals for the specified goal. The intervals are returned in descending order (most recent first).
     *
     * @tags Goals
     * @name GoalsMetricsIntervalsDetail
     * @summary List goal metric intervals
     * @request GET:/v1/goals/{id}/metrics/intervals
     * @secure
     */
    goalsMetricsIntervalsDetail: ({ id, ...query }: GoalsMetricsIntervalsDetailParams, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            items: IntervalGoalMetrics[]
          }
          meta: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/goals/${id}/metrics/intervals`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesList
     * @summary List languages
     * @request GET:/v1/languages
     * @secure
     */
    languagesList: (query: LanguagesListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: Language[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/languages`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesDetail
     * @summary Read a language
     * @request GET:/v1/languages/{code}
     * @secure
     */
    languagesDetail: (code: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: Language
          }
        },
        Fail | Error
      >({
        path: `/v1/languages/${code}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesPhonemesDetail
     * @summary Read a language's phonemes
     * @request GET:/v1/languages/{code}/phonemes
     * @secure
     */
    languagesPhonemesDetail: (code: string, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            items: Phoneme[]
          }
        },
        Fail | Error
      >({
        path: `/v1/languages/${code}/phonemes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesUtterancesCreate
     * @summary Find utterances
     * @request POST:/v1/languages/{code}/utterances
     * @secure
     */
    languagesUtterancesCreate: (code: string, data: UtteranceQuery, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            items: Utterance[]
          }
          meta: {
            /** @min 0 */
            pageIndex: number
            /** @min 1 */
            totalPages: number
            /** @min 1 */
            pageSize: number
            /** @min 0 */
            totalItems: number
          }
        },
        Fail | Error
      >({
        path: `/v1/languages/${code}/utterances`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesUtterancesAudioDetail
     * @summary Get the url of the audio
     * @request GET:/v1/languages/utterances/{id}/audio
     * @secure
     */
    languagesUtterancesAudioDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            item: UtteranceMediaLocation
          }
        },
        Fail | Error
      >({
        path: `/v1/languages/utterances/${id}/audio`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesUtterancesImageDetail
     * @summary Get the url of the image
     * @request GET:/v1/languages/utterances/{id}/image
     * @secure
     */
    languagesUtterancesImageDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            item: UtteranceMediaLocation
          }
        },
        Fail | Error
      >({
        path: `/v1/languages/utterances/${id}/image`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags License Agreement Accepts
     * @name LicenseAgreementsAcceptsList
     * @summary List license agreement with accepts
     * @request GET:/v1/license-agreements/accepts
     * @secure
     */
    licenseAgreementsAcceptsList: (query: LicenseAgreementsAcceptsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: AcceptedLicenseAgreement[]
          }
        },
        Fail | Error
      >({
        path: `/v1/license-agreements/accepts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags License Agreement Accepts
     * @name LicenseAgreementsAcceptsCreate
     * @summary Create a new license agreement accept
     * @request POST:/v1/license-agreements/{id}/accepts
     * @secure
     */
    licenseAgreementsAcceptsCreate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AcceptedLicenseAgreement
          }
        },
        Fail | Error
      >({
        path: `/v1/license-agreements/${id}/accepts`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags License Agreements
     * @name LicenseAgreementsList
     * @summary List license agreements
     * @request GET:/v1/license-agreements
     * @secure
     */
    licenseAgreementsList: (query: LicenseAgreementsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: LicenseAgreement[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/license-agreements`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags License Agreements
     * @name LicenseAgreementsCreate
     * @summary Create a new license agreement
     * @request POST:/v1/license-agreements
     * @secure
     */
    licenseAgreementsCreate: (
      data: {
        type: LicenseAgreementType
        mediaType: LicenseAgreementMediaType
        body: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: LicenseAgreement
          }
        },
        Fail | Error
      >({
        path: `/v1/license-agreements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags License Agreements
     * @name LicenseAgreementsDetail
     * @summary Read a license agreement
     * @request GET:/v1/license-agreements/{id}
     * @secure
     */
    licenseAgreementsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: LicenseAgreement
          }
        },
        Fail | Error
      >({
        path: `/v1/license-agreements/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags License Agreements
     * @name LicenseAgreementsUpdate
     * @summary Update a license agreement
     * @request PUT:/v1/license-agreements/{id}
     * @secure
     */
    licenseAgreementsUpdate: (
      id: string,
      data: {
        type: LicenseAgreementType
        mediaType: LicenseAgreementMediaType
        body: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: LicenseAgreement
          }
        },
        Fail | Error
      >({
        path: `/v1/license-agreements/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags License Agreements
     * @name LicenseAgreementsCurrentUpdate
     * @summary Mark a license agreement as current
     * @request PUT:/v1/license-agreements/{id}/current
     * @secure
     */
    licenseAgreementsCurrentUpdate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          code: 200
          status: 'success'
          data: {
            item?: LicenseAgreement
          }
        },
        Fail | Error
      >({
        path: `/v1/license-agreements/${id}/current`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name OrganizationsList
     * @summary List organizations
     * @request GET:/v1/organizations
     * @secure
     */
    organizationsList: (query: OrganizationsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: Organization[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/organizations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name OrganizationsCreate
     * @summary Create a new organization
     * @request POST:/v1/organizations
     * @secure
     */
    organizationsCreate: (
      data: {
        active: boolean
        name: string
        shortName: string
        defaultLanguageCode?: string | null
        ownerFullName: string
        /** @format email */
        ownerEmailAddress: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: Organization
          }
        },
        Fail | Error
      >({
        path: `/v1/organizations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name OrganizationsDetail
     * @summary Read an organization
     * @request GET:/v1/organizations/{id}
     * @secure
     */
    organizationsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: Organization
          }
        },
        Fail | Error
      >({
        path: `/v1/organizations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name OrganizationsUpdate
     * @summary Update an organization
     * @request PUT:/v1/organizations/{id}
     * @secure
     */
    organizationsUpdate: (
      id: string,
      data: {
        name: string
        shortName: string
        defaultLanguageCode?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: Organization
          }
        },
        Fail | Error
      >({
        path: `/v1/organizations/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name OrganizationsActiveUpdate
     * @summary Update an organization's active status
     * @request PUT:/v1/organizations/{id}/active
     * @secure
     */
    organizationsActiveUpdate: (
      id: string,
      data: {
        active: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          code: 200
          status: 'success'
          data: {
            item?: Organization
          }
        },
        Fail | Error
      >({
        path: `/v1/organizations/${id}/active`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name OrganizationsTypeUpdate
     * @summary Update an organization's type
     * @request PUT:/v1/organizations/{id}/type
     * @secure
     */
    organizationsTypeUpdate: (
      id: string,
      data: {
        type: OrganizationType
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: Organization
          }
        },
        Fail | Error
      >({
        path: `/v1/organizations/${id}/type`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Patients
     * @name PatientsList
     * @summary List patients
     * @request GET:/v1/patients
     * @secure
     */
    patientsList: (query: PatientsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            items: Patient[]
          }
          meta: {
            /** @min 0 */
            pageIndex: number
            /** @min 1 */
            totalPages: number
            /** @min 1 */
            pageSize: number
            /** @min 0 */
            totalItems: number
            defaults: PatientRecordDefaults
          }
        },
        Fail | Error
      >({
        path: `/v1/patients`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Platform
     * @name PlatformPingList
     * @summary Check if the platform is responding
     * @request GET:/v1/platform/ping
     */
    platformPingList: (params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/platform/ping`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recordings
     * @name RecordingsList
     * @summary List recordings
     * @request GET:/v1/recordings
     * @secure
     */
    recordingsList: (query: RecordingsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            items: Recording[]
          }
          meta: {
            /** @min 0 */
            pageIndex: number
            /** @min 1 */
            totalPages: number
            /** @min 1 */
            pageSize: number
            /** @min 0 */
            totalItems: number
          }
        },
        Fail | Error
      >({
        path: `/v1/recordings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recordings
     * @name RecordingsCreate
     * @summary Create a recording
     * @request POST:/v1/recordings
     * @secure
     */
    recordingsCreate: (
      data: {
        /** @format uuid */
        practiceItemId: string
        /** @format date-time */
        recordedAt: DateLike
        mediaDetail: RecordingMediaDetail[]
        /** @min 0 */
        seconds: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status: 'success'
          data: {
            item: Recording
          }
        },
        Fail | Error
      >({
        path: `/v1/recordings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recordings
     * @name RecordingsDetail
     * @summary Read a recording
     * @request GET:/v1/recordings/{id}
     * @secure
     */
    recordingsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            item: Recording
          }
        },
        Fail | Error
      >({
        path: `/v1/recordings/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recordings
     * @name RecordingsMediaReadDetail
     * @summary Get the url of media for reading
     * @request GET:/v1/recordings/media/{id}/read
     * @secure
     */
    recordingsMediaReadDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          /** @example {"method":"GET","url":"https://storage.googleapis.com/speaksuite-us/recordings/956aa072-c420-4054-a9f9-5efa2888c1e8.wav","expiresAt":"2021-01-01T00:00:00.000Z"} */
          data: {
            item: RecordingMediaLocation
          }
        },
        Fail | Error
      >({
        path: `/v1/recordings/media/${id}/read`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recordings
     * @name RecordingsMediaWriteDetail
     * @summary Get the url of media for writing
     * @request GET:/v1/recordings/media/{id}/write
     * @secure
     */
    recordingsMediaWriteDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          /** @example {"method":"PUT","url":"https://storage.googleapis.com/speaksuite-us/recordings/956aa072-c420-4054-a9f9-5efa2888c1e8.wav","expiresAt":"2021-01-01T00:00:00.000Z"} */
          data: {
            item: RecordingMediaLocation
          }
        },
        Fail | Error
      >({
        path: `/v1/recordings/media/${id}/write`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recordings
     * @name RecordingsRatingsList
     * @summary List ratings
     * @request GET:/v1/recordings/ratings
     * @secure
     */
    recordingsRatingsList: (query: RecordingsRatingsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            items: Rating[]
          }
          meta: {
            /** @min 0 */
            pageIndex: number
            /** @min 1 */
            totalPages: number
            /** @min 1 */
            pageSize: number
            /** @min 0 */
            totalItems: number
          }
        },
        Fail | Error
      >({
        path: `/v1/recordings/ratings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recordings
     * @name RecordingsRatingsCreate
     * @summary Create a rating
     * @request POST:/v1/recordings/ratings
     * @secure
     */
    recordingsRatingsCreate: (
      data: {
        /** @format uuid */
        recordingId: string
        value: RatingValue
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status: 'success'
          data: {
            item: Rating
          }
        },
        Fail | Error
      >({
        path: `/v1/recordings/ratings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsAssistantToSpeechLanguagePathologistCreate
     * @summary Create an assistant to speech language pathologist relationship
     * @request POST:/v1/relationships/assistant-to-speech-language-pathologist
     * @secure
     */
    relationshipsAssistantToSpeechLanguagePathologistCreate: (
      data: {
        /** @format uuid */
        assistantRoleAssignmentId: string
        /** @format uuid */
        speechLanguagePathologistRoleAssignmentId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AssistantToSpeechLanguagePathologistRelationship
          }
        },
        Fail | Error
      >({
        path: `/v1/relationships/assistant-to-speech-language-pathologist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsAssistantToSpeechLanguagePathologistDetail
     * @summary Read an assistant to speech language pathologist relationship
     * @request GET:/v1/relationships/assistant-to-speech-language-pathologist/{id}
     * @secure
     */
    relationshipsAssistantToSpeechLanguagePathologistDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AssistantToSpeechLanguagePathologistRelationship
          }
        },
        Fail | Error
      >({
        path: `/v1/relationships/assistant-to-speech-language-pathologist/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsAssistantToSpeechLanguagePathologistDelete
     * @summary Delete an assistant to speech language pathologist relationship
     * @request DELETE:/v1/relationships/assistant-to-speech-language-pathologist/{id}
     * @secure
     */
    relationshipsAssistantToSpeechLanguagePathologistDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/relationships/assistant-to-speech-language-pathologist/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsList
     * @summary List relationships
     * @request GET:/v1/relationships
     * @secure
     */
    relationshipsList: (query: RelationshipsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: (
              | AssistantToSpeechLanguagePathologistRelationship
              | PatientToCaregiverRelationship
              | PatientToSpeechLanguagePathologistRelationship
            )[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/relationships`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsPatientToCaregiverCreate
     * @summary Create a patient to caregiver relationship
     * @request POST:/v1/relationships/patient-to-caregiver
     * @secure
     */
    relationshipsPatientToCaregiverCreate: (
      data: {
        /** @format uuid */
        patientRoleAssignmentId: string
        /** @format uuid */
        caregiverRoleAssignmentId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: PatientToCaregiverRelationship
          }
        },
        Fail | Error
      >({
        path: `/v1/relationships/patient-to-caregiver`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsPatientToCaregiverDetail
     * @summary Read a patient to caregiver relationship
     * @request GET:/v1/relationships/patient-to-caregiver/{id}
     * @secure
     */
    relationshipsPatientToCaregiverDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: PatientToCaregiverRelationship
          }
        },
        Fail | Error
      >({
        path: `/v1/relationships/patient-to-caregiver/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsPatientToCaregiverDelete
     * @summary Delete a patient to caregiver relationship
     * @request DELETE:/v1/relationships/patient-to-caregiver/{id}
     * @secure
     */
    relationshipsPatientToCaregiverDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/relationships/patient-to-caregiver/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsPatientToSpeechLanguagePathologistCreate
     * @summary Create a patient to speech language pathologist relationship
     * @request POST:/v1/relationships/patient-to-speech-language-pathologist
     * @secure
     */
    relationshipsPatientToSpeechLanguagePathologistCreate: (
      data: {
        /** @format uuid */
        patientRoleAssignmentId: string
        /** @format uuid */
        speechLanguagePathologistRoleAssignmentId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: PatientToSpeechLanguagePathologistRelationship
          }
        },
        Fail | Error
      >({
        path: `/v1/relationships/patient-to-speech-language-pathologist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsPatientToSpeechLanguagePathologistDetail
     * @summary Read a patient to speech language pathologist relationship
     * @request GET:/v1/relationships/patient-to-speech-language-pathologist/{id}
     * @secure
     */
    relationshipsPatientToSpeechLanguagePathologistDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: PatientToSpeechLanguagePathologistRelationship
          }
        },
        Fail | Error
      >({
        path: `/v1/relationships/patient-to-speech-language-pathologist/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Relationships
     * @name RelationshipsPatientToSpeechLanguagePathologistDelete
     * @summary Delete a patient to speech language pathologist relationship
     * @request DELETE:/v1/relationships/patient-to-speech-language-pathologist/{id}
     * @secure
     */
    relationshipsPatientToSpeechLanguagePathologistDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/relationships/patient-to-speech-language-pathologist/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsComplianceCreate
     * @summary Create an compliance specialist role assignment
     * @request POST:/v1/roles/assignments/compliance
     * @secure
     */
    rolesAssignmentsComplianceCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: ComplianceRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/compliance`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsComplianceDetail
     * @summary Read an compliance specialist role assignment
     * @request GET:/v1/roles/assignments/compliance/{id}
     * @secure
     */
    rolesAssignmentsComplianceDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: ComplianceRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/compliance/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsComplianceDelete
     * @summary Delete an compliance specialist role assignment
     * @request DELETE:/v1/roles/assignments/compliance/{id}
     * @secure
     */
    rolesAssignmentsComplianceDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/compliance/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsCustomerSuccessCreate
     * @summary Create a customer success specialist role assignment
     * @request POST:/v1/roles/assignments/customer-success
     * @secure
     */
    rolesAssignmentsCustomerSuccessCreate: (
      data: {
        /** @format uuid */
        userId: string
        canUseAccessDeclarations: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: CustomerSuccessRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/customer-success`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsCustomerSuccessDetail
     * @summary Read a customer success specialist role assignment
     * @request GET:/v1/roles/assignments/customer-success/{id}
     * @secure
     */
    rolesAssignmentsCustomerSuccessDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: CustomerSuccessRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/customer-success/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsCustomerSuccessUpdate
     * @summary Update a customer success specialist role assignment
     * @request PUT:/v1/roles/assignments/customer-success/{id}
     * @secure
     */
    rolesAssignmentsCustomerSuccessUpdate: (
      id: string,
      data: {
        canUseAccessDeclarations: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: CustomerSuccessRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/customer-success/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsCustomerSuccessDelete
     * @summary Delete a customer success specialist role assignment
     * @request DELETE:/v1/roles/assignments/customer-success/{id}
     * @secure
     */
    rolesAssignmentsCustomerSuccessDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/customer-success/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsTechnicalSupportCreate
     * @summary Create a technical support specialist role assignment
     * @request POST:/v1/roles/assignments/technical-support
     * @secure
     */
    rolesAssignmentsTechnicalSupportCreate: (
      data: {
        /** @format uuid */
        userId: string
        canUseAccessDeclarations: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: TechnicalSupportRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/technical-support`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsTechnicalSupportDetail
     * @summary Read a technical support specialist role assignment
     * @request GET:/v1/roles/assignments/technical-support/{id}
     * @secure
     */
    rolesAssignmentsTechnicalSupportDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: TechnicalSupportRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/technical-support/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsTechnicalSupportUpdate
     * @summary Update a technical support specialist role assignment
     * @request PUT:/v1/roles/assignments/technical-support/{id}
     * @secure
     */
    rolesAssignmentsTechnicalSupportUpdate: (
      id: string,
      data: {
        canUseAccessDeclarations: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: TechnicalSupportRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/technical-support/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsTechnicalSupportDelete
     * @summary Delete a technical support specialist role assignment
     * @request DELETE:/v1/roles/assignments/technical-support/{id}
     * @secure
     */
    rolesAssignmentsTechnicalSupportDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/technical-support/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsList
     * @summary List role assignments
     * @request GET:/v1/roles/assignments
     * @secure
     */
    rolesAssignmentsList: (query: RolesAssignmentsListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: (
              | AssistantRoleAssignment
              | AuditRoleAssignment
              | BillingRoleAssignment
              | CaregiverRoleAssignment
              | ComplianceRoleAssignment
              | CustomerSuccessRoleAssignment
              | DirectorRoleAssignment
              | OwnerRoleAssignment
              | PatientRoleAssignment
              | SpeechLanguagePathologistRoleAssignment
              | TechnicalSupportRoleAssignment
            )[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsAssistantCreate
     * @summary Create an assistant role assignment
     * @request POST:/v1/roles/assignments/assistant
     * @secure
     */
    rolesAssignmentsAssistantCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AssistantRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/assistant`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsAssistantDetail
     * @summary Read an assistant role assignment
     * @request GET:/v1/roles/assignments/assistant/{id}
     * @secure
     */
    rolesAssignmentsAssistantDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AssistantRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/assistant/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsAssistantDelete
     * @summary Delete an assistant role assignment
     * @request DELETE:/v1/roles/assignments/assistant/{id}
     * @secure
     */
    rolesAssignmentsAssistantDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/assistant/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsAuditCreate
     * @summary Create an audit specialist role assignment
     * @request POST:/v1/roles/assignments/audit
     * @secure
     */
    rolesAssignmentsAuditCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AuditRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/audit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsAuditDetail
     * @summary Read an audit specialist role assignment
     * @request GET:/v1/roles/assignments/audit/{id}
     * @secure
     */
    rolesAssignmentsAuditDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: AuditRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/audit/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsAuditDelete
     * @summary Delete an audit specialist role assignment
     * @request DELETE:/v1/roles/assignments/audit/{id}
     * @secure
     */
    rolesAssignmentsAuditDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/audit/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsBillingCreate
     * @summary Create a billing specialist role assignment
     * @request POST:/v1/roles/assignments/billing
     * @secure
     */
    rolesAssignmentsBillingCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: BillingRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/billing`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsBillingDetail
     * @summary Read a billing specialist role assignment
     * @request GET:/v1/roles/assignments/billing/{id}
     * @secure
     */
    rolesAssignmentsBillingDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: BillingRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/billing/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsBillingDelete
     * @summary Delete a billing specialist role assignment
     * @request DELETE:/v1/roles/assignments/billing/{id}
     * @secure
     */
    rolesAssignmentsBillingDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/billing/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsDirectorCreate
     * @summary Create a director role assignment
     * @request POST:/v1/roles/assignments/director
     * @secure
     */
    rolesAssignmentsDirectorCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: DirectorRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/director`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsDirectorDetail
     * @summary Read a director role assignment
     * @request GET:/v1/roles/assignments/director/{id}
     * @secure
     */
    rolesAssignmentsDirectorDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: DirectorRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/director/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsDirectorDelete
     * @summary Delete a director role assignment
     * @request DELETE:/v1/roles/assignments/director/{id}
     * @secure
     */
    rolesAssignmentsDirectorDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/director/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsOwnerCreate
     * @summary Create an owner role assignment
     * @request POST:/v1/roles/assignments/owner
     * @secure
     */
    rolesAssignmentsOwnerCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: OwnerRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/owner`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsOwnerDetail
     * @summary Read an owner role assignment
     * @request GET:/v1/roles/assignments/owner/{id}
     * @secure
     */
    rolesAssignmentsOwnerDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: OwnerRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/owner/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsOwnerDelete
     * @summary Delete an owner role assignment
     * @request DELETE:/v1/roles/assignments/owner/{id}
     * @secure
     */
    rolesAssignmentsOwnerDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/owner/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsSpeechLanguagePathologistCreate
     * @summary Create a speech language pathologist role assignment
     * @request POST:/v1/roles/assignments/speech-language-pathologist
     * @secure
     */
    rolesAssignmentsSpeechLanguagePathologistCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: SpeechLanguagePathologistRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/speech-language-pathologist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsSpeechLanguagePathologistDetail
     * @summary Read a speech language pathologist role assignment
     * @request GET:/v1/roles/assignments/speech-language-pathologist/{id}
     * @secure
     */
    rolesAssignmentsSpeechLanguagePathologistDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: SpeechLanguagePathologistRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/speech-language-pathologist/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsSpeechLanguagePathologistDelete
     * @summary Delete a speech language pathologist role assignment
     * @request DELETE:/v1/roles/assignments/speech-language-pathologist/{id}
     * @secure
     */
    rolesAssignmentsSpeechLanguagePathologistDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/speech-language-pathologist/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsCaregiverCreate
     * @summary Create a caregiver role assignment
     * @request POST:/v1/roles/assignments/caregiver
     * @secure
     */
    rolesAssignmentsCaregiverCreate: (
      data: {
        /** @format uuid */
        userId: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: CaregiverRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/caregiver`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsCaregiverDetail
     * @summary Read a caregiver role assignment
     * @request GET:/v1/roles/assignments/caregiver/{id}
     * @secure
     */
    rolesAssignmentsCaregiverDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: CaregiverRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/caregiver/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsCaregiverDelete
     * @summary Delete a caregiver role assignment
     * @request DELETE:/v1/roles/assignments/caregiver/{id}
     * @secure
     */
    rolesAssignmentsCaregiverDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/caregiver/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsPatientCreate
     * @summary Create a patient role assignment
     * @request POST:/v1/roles/assignments/patient
     * @secure
     */
    rolesAssignmentsPatientCreate: (
      data: {
        /** @format uuid */
        userId: string
        active: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: PatientRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/patient`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsPatientDetail
     * @summary Read a patient role assignment
     * @request GET:/v1/roles/assignments/patient/{id}
     * @secure
     */
    rolesAssignmentsPatientDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: PatientRoleAssignment
          }
        },
        Fail | Error
      >({
        path: `/v1/roles/assignments/patient/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role Assignments
     * @name RolesAssignmentsPatientDelete
     * @summary Delete a patient role assignment
     * @request DELETE:/v1/roles/assignments/patient/{id}
     * @secure
     */
    rolesAssignmentsPatientDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/roles/assignments/patient/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersList
     * @summary List users
     * @request GET:/v1/users
     * @secure
     */
    usersList: (query: UsersListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: User[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersCreate
     * @summary Create a new user
     * @request POST:/v1/users
     * @secure
     */
    usersCreate: (
      data: {
        /** @format uuid */
        organizationId: string
        active: boolean
        fullName: string
        /** @format email */
        emailAddress?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: User
          }
        },
        Fail | Error
      >({
        path: `/v1/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersDetail
     * @summary Read a user
     * @request GET:/v1/users/{id}
     * @secure
     */
    usersDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: User
          }
        },
        Fail | Error
      >({
        path: `/v1/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdate
     * @summary Update a user
     * @request PUT:/v1/users/{id}
     * @secure
     */
    usersUpdate: (
      id: string,
      data: {
        active: boolean
        fullName: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: User
          }
        },
        Fail | Error
      >({
        path: `/v1/users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersEmailAddressesList
     * @summary List email addresses
     * @request GET:/v1/users/email-addresses
     * @secure
     */
    usersEmailAddressesList: (query: UsersEmailAddressesListParams, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: EmailAddress[]
          }
          meta?: {
            /** @min 0 */
            pageIndex?: number
            /** @min 1 */
            totalPages?: number
            /** @min 1 */
            pageSize?: number
            /** @min 0 */
            totalItems?: number
          }
        },
        Fail | Error
      >({
        path: `/v1/users/email-addresses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersEmailAddressesCreate
     * @summary Create a new email Address
     * @request POST:/v1/users/email-addresses
     * @secure
     */
    usersEmailAddressesCreate: (
      data: {
        /** @format uuid */
        userId: string
        /** @format email */
        emailAddress: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: EmailAddress
          }
        },
        Fail | Error
      >({
        path: `/v1/users/email-addresses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersEmailAddressesDetail
     * @summary Read an email address
     * @request GET:/v1/users/email-addresses/{id}
     * @secure
     */
    usersEmailAddressesDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: EmailAddress
          }
        },
        Fail | Error
      >({
        path: `/v1/users/email-addresses/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersEmailAddressesDelete
     * @summary Deletes an email address
     * @request DELETE:/v1/users/email-addresses/{id}
     * @secure
     */
    usersEmailAddressesDelete: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Fail | Error
      >({
        path: `/v1/users/email-addresses/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersEmailAddressesPrimaryUpdate
     * @summary Update the email address to be the primary one for the user
     * @request PUT:/v1/users/email-addresses/{id}/primary
     * @secure
     */
    usersEmailAddressesPrimaryUpdate: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status?: 'success'
          data?: {
            item?: EmailAddress
          }
        },
        Fail | Error
      >({
        path: `/v1/users/email-addresses/${id}/primary`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Creates a new passcode for a user, which replaces the old one.
     *
     * @tags Users
     * @name UsersPasscodesCreate
     * @summary Create a new passcode
     * @request POST:/v1/users/{id}/passcodes
     * @secure
     */
    usersPasscodesCreate: (
      id: string,
      data: {
        type: 'short'
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data: {
            item: Passcode
          }
        },
        Error
      >({
        path: `/v1/users/${id}/passcodes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersPasswordsUpdate
     * @summary Update a user's password
     * @request PUT:/v1/users/{id}/passwords
     * @secure
     */
    usersPasswordsUpdate: (
      id: string,
      data: {
        /** @format password */
        currentPassword: string
        /** @format password */
        newPassword: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Fail | Error
      >({
        path: `/v1/users/${id}/passwords`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersPasswordsResetsCreate
     * @summary Create a password reset
     * @request POST:/v1/users/passwords/resets
     */
    usersPasswordsResetsCreate: (
      data: {
        organizationShortName: string
        /** @format email */
        emailAddress: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Error
      >({
        path: `/v1/users/passwords/resets`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersPasswordsResetsUpdate
     * @summary Update a user's password
     * @request PUT:/v1/users/passwords/resets/{id}
     */
    usersPasswordsResetsUpdate: (
      id: string,
      data: {
        /** @format password */
        code: string
        /** @format password */
        newPassword: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: object | null
        },
        Fail | Error
      >({
        path: `/v1/users/passwords/resets/${id}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersRecordsPatientCreate
     * @summary Create a patient record
     * @request POST:/v1/users/{id}/records/patient
     * @secure
     */
    usersRecordsPatientCreate: (
      id: string,
      data: {
        /**
         * Use 'languageCode' instead
         * @deprecated
         */
        defaultLanguageCode?: string | null
        languageCode?: string | null
        /** @min 1 */
        birthMonth?: number | null
        /** @min 1900 */
        birthYear?: number | null
        /**
         * Use 'playSeconds' instead
         * @deprecated
         * @min 1
         */
        playMinutes?: number | null
        /** @min 1 */
        playSeconds?: number | null
        /** @min 1 */
        practiceCount?: number | null
        automaticallyAdvance?: boolean | null
        provideFeedback?: boolean | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status: 'success'
          data: {
            item?: PatientRecord
          }
          meta: {
            defaults: PatientRecordDefaults
          }
        },
        Fail | Error
      >({
        path: `/v1/users/${id}/records/patient`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersRecordsPatientDetail
     * @summary Read a patient record
     * @request GET:/v1/users/{id}/records/patient
     * @secure
     */
    usersRecordsPatientDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status: 'success'
          data: {
            item?: PatientRecord
          }
          meta: {
            defaults: PatientRecordDefaults
          }
        },
        Fail | Error
      >({
        path: `/v1/users/${id}/records/patient`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersRecordsPatientVersionsDetail
     * @summary List patient record versions
     * @request GET:/v1/users/{id}/records/patient/versions
     * @secure
     */
    usersRecordsPatientVersionsDetail: (
      { id, ...query }: UsersRecordsPatientVersionsDetailParams,
      params: RequestParams = {}
    ) =>
      this.request<
        {
          status?: 'success'
          data?: {
            items?: PatientRecord[]
          }
          meta?: {
            /** @min 0 */
            pageIndex: number
            /** @min 1 */
            totalPages: number
            /** @min 1 */
            pageSize: number
            /** @min 0 */
            totalItems: number
            defaults: PatientRecordDefaults
          }
        },
        Fail | Error
      >({
        path: `/v1/users/${id}/records/patient/versions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
