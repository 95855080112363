import { z } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { FormSchema } from '.'
import { LicenseAgreement } from '@/models'


export const licenseAgreementSchema: FormSchema<LicenseAgreement> = {
  fields: [
    {
      name: 'type',
      label: 'Type',
      as: 'select',
      options: [
        { label: 'End User', value: 'enduser' },
        { label: 'Provider', value: 'provider' },
      ],
      rules: toTypedSchema(z.string())
    },
    {
      name: 'body',
      label: 'Body',
      as: 'textarea',
      rows: 10,
      rules: toTypedSchema(z.string())
    },
  ]
}
