import { ref } from 'vue'

/*
  tracks if a newer version of the site is available by comparing a remote
  version of the index.html file with the local version to see if their
  meta tag versions match.
*/
export function useOutdated(
  fetchInterval = 1000 * 30, // 30 seconds
  metaTagName = 'version',
  fetchUrl = '/index.html'
) {
  const parser = new DOMParser()

  // get the version from an html meta tag
  const getDocumentVersion = (doc: Document) => {
    return doc
      .querySelector(`meta[name="${metaTagName}"]`)
      ?.getAttribute('content')
  }

  const getLocalVersion = () => {
    return getDocumentVersion(document)
  }

  const getRemoteVersion = async () => {
    // fetch the index.html file from this site
    // and parse the version from the meta tag
    return await fetch(fetchUrl)
      .then((response) => response.text())
      .then((html) => {
        const doc = parser.parseFromString(html, 'text/html')
        return getDocumentVersion(doc)
      })
  }

  const timer = setInterval(async () => {
    const local = getLocalVersion()
    localVersion.value = local

    // skip if there is an error fetching and try again on the next interval
    try {
      const remote = await getRemoteVersion()

      if (local !== remote) {
        outdated.value = true
        clearInterval(timer)
      }
    } catch (err) {
      console.error(err)
    }
  }, fetchInterval)

  const localVersion = ref(getLocalVersion())
  const outdated = ref(false)

  return outdated
}
