<script setup lang="ts">
import { computed } from 'vue'
import { useApi, useQuery } from '@/composables'
import { AccessDeclaration } from '@/models'
import { normalizeDate, formatDateTime } from '@/utils'
import { Badge, DetailList, Panel, TransitionList } from '@/components'

const api = useApi()

const { result } = useQuery(api.v1.declarationsAccessList, {})

const declarations = computed(() =>
  (result.value?.data?.data?.items || []).map(
    (declaration) =>
      ({
        ...declaration,
        active:
          normalizeDate(declaration.expiresAt || '').getTime() > Date.now(),
      } as AccessDeclaration & { active: boolean })
  )
)
</script>

<template>
  <PageLayout :title="$t('pages.accessDeclarations.title')">
    <template #default>
      <TransitionList
        v-slot="{ item: declaration }"
        :items="declarations"
        item-key="id"
      >
        <Panel>
          <template #header>
            <span
              class="font-semibold"
              :class="{ 'text-success-500': declaration.active }"
            >
              <span class="whitespace-nowrap">
                {{ formatDateTime(declaration.createdAt || '') }}
              </span>
              -
              <span class="whitespace-nowrap">
                {{ formatDateTime(declaration.expiresAt || '') }}
              </span>
            </span>

            <Badge v-if="declaration.active" variant="success" class="ml-5">
              {{ $t('pages.accessDeclarations.activeBadge') }}
            </Badge>
          </template>

          <template #default>
            <div>{{ declaration.reason }}</div>
          </template>

          <template #footer>
            <DetailList
              :entries="declaration"
              :fields="['userFullName', 'accessOrganizationName']"
              i18n-prefix="details.accessDeclarations"
            >
              <template #userFullName-value>
                <RouterLink
                  :to="{
                    name: 'users-edit',
                    params: { id: declaration.userId },
                  }"
                >
                  {{ declaration.userFullName }}
                </RouterLink>
              </template>

              <template #accessOrganizationName-value>
                <RouterLink
                  :to="{
                    name: 'organizations-edit',
                    params: { id: declaration.accessOrganizationId },
                  }"
                >
                  {{ declaration.accessOrganizationName }}
                </RouterLink>
              </template>
            </DetailList>
          </template>
        </Panel>
      </TransitionList>
    </template>
  </PageLayout>
</template>
