---
# brand
title: Console

# top level site navigation
nav:
  home: Home
  organizations: Organizations
  users: Users
  accessDeclarations: Access Declarations
  licenseAgreements: License Agreements
  endUser: End User
  provider: Provider
  login: Sign In
  logout: Sign Out

# used in combination with "<action>Model" keys
# intended to dry up "create item", "create other", etc.
models:
  item: Item
  organization: Organization
  organizations: Organizations
  accessDeclaration: Access Declaration

# used for buttons and menu items
# note that "<action>Model" keys should be used with models keys
actions:
  login: Sign In
  logout: Sign Out
  create: Create
  createModel: Create {0}
  edit: Edit
  delete: Delete
  update: Update
  confirm: Confirm
  cancel: Cancel
  save: Save
  clear: Clear
  submit: Submit
  add: Add
  publish: Publish
  view: View
  remainLoggedIn: I'm Back

# used for prompts and alerts
messages:
  noItems: There are currently no items
  confirm: Are you sure?
  'yes': 'Yes'
  'no': 'No'
  na: —
  idle: You have been idle since %{time}. You will be automatically signed out %{cutoff} unless you return.
  redirecting: Redirecting...
  outdated: A newer version of SpeakSuite is available! Click here to update

# form labels should have no suffix (eg: "email")
# placeholders should have "<field>Placeholder" suffix
# help text should have "<field>Help" suffix
forms:
  login:
    organization: Organization
    organizationPlaceholder: Organization
    email: Email
    emailPlaceholder: Email
    password: Password
    passwordPlaceholder: Password
    showPassword: Show password
  filter:
    term: Filter
    termPlaceholder: Filter by name...
    resultCount: '{count} of {total} results'
  rolePicker:
    roleSelect: Role
    rolePlaceholder: Select a role
    accessDeclarationsCheck: Can use access declarations
    accessDeclarationsBadge: Access Declarations
  accessDeclarations:
    title: Create Access Declaration for {0}
    reason: Reason

# component-specific copy only
components:
  idleMonitor:
    title: Idle Notice
    message: Idle since %{time}. Cutoff will trigger %{cutoff}.

# page-specific headings and copy only
# make sure keys that belong in actions or messages do not go here!
pages:
  home:
    title: Home

  organizations:
    title: Organizations
  organizationsEdit:
    title: Edit Organization
  organizationsCreate:
    title: Create Organization

  users:
    title: Users
  usersCreate:
    title: Create User
  usersEdit:
    title: Edit User
    userDetailsTitle: User Details
    emailsTitle: Email Addresses
    rolesTitle: Roles

  accessDeclarations:
    title: Access Declarations
    userLabel: User
    organizationLabel: Organization
    timePeriodLabel: Time Period
    reasonLabel: Reason
    activeBadge: Active

  licenseAgreements:
    title: License Agreements
    createdAt: Created {date}
    publishedAt: Published {date}
    current: Current
    draft: Draft
    enduserAgreements: End User Agreements
    providerAgreements: Provider Agreements
  licenseAgreementsCreate:
    title: Create License Agreement
  licenseAgreementsEdit:
    editTitle: Edit License Agreement
    viewTitle: View License Agreement
    enduserType: End User License Agreement
    providerType: Provider License Agreement
    publishedAt: Published {date}
    createdAt: Created {date}

  login:
    title: Sign In
  logout:
    title: Sign Out
    confirm: Are you sure you want to sign out?
  notFound:
    title: Page Not Found
    message: The page you are looking for does not exist.

roles:
  owner: Owner
  assistant: Assistant
  audit: Audit
  billing: Billing
  patient: Patient
  compliance: Compliance
  customer-success: Customer Success
  technical-support: Technical Support
  director: Director
  caregiver: Caregiver
  speech-language-pathologist: Speech Language Pathologist

details:
  users:
    roles: Roles
    activeAt: Last Active
    organizationName: Organization

  accessDeclarations:
    userFullName: User
    accessOrganizationName: Organization

  licenseAgreements:
    current: Current
    publishedAt: Published On
    body: Body
    actions: Actions
