<script setup lang="ts" generic="T">
import { Field } from 'vee-validate'
import { FormSchemaField } from '.'
import { toRef } from 'vue'
import { toRefs } from '@vueuse/core'

const props = defineProps<{
  disabled?: boolean
  field: FormSchemaField<T>
}>()

const {
  as = 'input',
  name,
  label,
  placeholder,
  help,
  badge,
  // `options` is getting typed as any here for some reason. Still want to pull it
  // off of the object, but we just use props.field.options instead for proper typing
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  options,
  hideLabel,
  ...rest
} = toRefs(toRef(props.field))

const attrs = toRef(rest)
</script>

<template>
  <label
    v-if="!hideLabel && field.type !== 'checkbox' && field.type !== 'radio'"
    :for="String(name)"
    class="mb-1 block"
  >
    {{ label }}

    <span v-if="badge" class="text-muted text-sm">({{ badge }})</span>
  </label>

  <Field
    :id="name"
    :as="as || 'input'"
    :name="String(name)"
    :placeholder="placeholder"
    :value="
      field.type === 'checkbox' || field.type === 'radio' ? true : field.value
    "
    :unchecked-value="false"
    :disabled="disabled"
    :type="field.type"
    v-bind="attrs"
  >
    <template v-if="field.options">
      <option value="" disabled selected hidden />

      <option
        v-for="option in field.options"
        :key="option.value"
        :value="option.value"
        :disabled="option.disabled"
      >
        {{ option.label }}
      </option>
    </template>
  </Field>

  <label
    v-if="!hideLabel && (field.type === 'checkbox' || field.type === 'radio')"
    :for="String(name)"
    class="ml-3 mr-5"
  >
    {{ label }}
    <span v-if="badge" class="text-muted text-sm">({{ badge }})</span>
  </label>

  <div v-if="help" class="text-muted mt-1 text-sm">
    {{ help }}
  </div>
</template>
