export { default as EditList } from './EditList.vue'
import { ref, Ref, readonly, computed } from 'vue'

// TODO: https://vueuse.org/core/createUnrefFn/

export type EditState = 'none' | 'add' | 'edit'

export const useEditList = <T>(items: Ref<Array<T>>) => {
  const editIndex = ref(-1)
  const isEditing = computed(() => editIndex.value > -1)

  const editState = computed<EditState>(() => {
    if (editIndex.value === -1) return 'none'
    if (editIndex.value === items.value.length) return 'add'
    return 'edit'
  })

  const editItem = computed(() =>
    editIndex.value > -1 ? items.value[editIndex.value] : null
  )

  // TODO: https://vueuse.org/shared/createEventHook/
  let editCallback: null | ((item: T) => void) = null
  let changeCallback: null | ((items: T[]) => void) = null
  let cancelCallback: null | (() => void) = null
  let insertCallback: null | ((item: T) => void) = null
  let updateCallback: null | ((newItem: T, oldItem: T) => void) = null
  let removeCallback: null | ((removedItem: T) => void) = null

  // TODO: hook callbacks for moveUp, moveDown

  const onEdit = (callback: (item: T) => void) => editCallback = callback
  const onChange = (callback: (items: T[]) => void) => changeCallback = callback
  const onCancel = (callback: () => void) => cancelCallback = callback
  const onInsert = (callback: (item: T) => void) => insertCallback = callback
  const onUpdate = (callback: (newItem: T, oldItem: T) => void) => updateCallback = callback
  const onRemove = (callback: (removedItem: T) => void) => removeCallback = callback

  const add = () => {
    editIndex.value = items.value.length
  }

  const insert = (item: T, index?: number) => {
    if (index !== undefined) {
      items.value.splice(index, 0, item)
    } else {
      items.value.push(item)
    }

    insertCallback?.(item)
    changeCallback?.(items.value)

    reset()
  }

  const edit = (index: number) => {
    editIndex.value = index
    if (editItem.value) editCallback?.(editItem.value)
  }

  const reset = () => {
    editIndex.value = -1
  }

  const cancel = () => {
    reset()
    cancelCallback?.()
  }

  // TODO: force update with index, maybe a new function?
  const update = (item: T) => {
    if (editIndex.value === -1) return

    const oldItem = items.value[editIndex.value]
    items.value[editIndex.value] = item

    updateCallback?.(item, oldItem)
    changeCallback?.(items.value)

    reset()
  }

  const remove = (index: number) => {
    const [item] = items.value.splice(index, 1)

    removeCallback?.(item)
    changeCallback?.(items.value)
  }

  const moveUp = (index: number) => {
    if (index === 0) return

    const item = items.value[index]
    items.value.splice(index, 1)
    items.value.splice(index - 1, 0, item)

    changeCallback?.(items.value)
  }

  const moveDown = (index: number) => {
    if (index === items.value.length - 1) return

    const item = items.value[index]
    items.value.splice(index, 1)
    items.value.splice(index + 1, 0, item)

    changeCallback?.(items.value)
  }

  return {
    editIndex: readonly(editIndex),
    editItem: readonly(editItem),
    isEditing: readonly(isEditing),
    editState: readonly(editState),

    add,
    edit,
    cancel,
    insert,
    update,
    remove,
    moveUp,
    moveDown,

    onEdit,
    onChange,
    onCancel,
    onInsert,
    onUpdate,
    onRemove,
  }
}
