import { z } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import { FormSchema, FormSchemaField } from '.'
import { EmailAddress, User } from '@/models'

const baseUserFields: FormSchemaField<User>[] = [
  {
    name: 'fullName',
    label: 'Name',
    type: 'text',
    rules: toTypedSchema(z.string().min(1)),
  },
]

const activeField: FormSchemaField<User> = {
  name: 'active',
  label: 'Active',
  type: 'checkbox',
  rules: toTypedSchema(z.boolean().optional()),
}

export const userCreateSchema: FormSchema<User> = {
  fields: [
    ...baseUserFields,
    {
      name: 'primaryEmailAddress',
      label: 'Email',
      type: 'email',
      rules: toTypedSchema(z.string().email()),
    },
    {
      name: 'organizationId',
      label: 'Organization ID',
      type: 'text',
      rules: toTypedSchema(z.string().uuid()),
    },
    activeField,
  ],
}

export const userEditSchema: FormSchema<User> = {
  fields: [...baseUserFields, activeField],
}

export const userEmailSchema: (
  existingEmails: EmailAddress[]
) => FormSchema<EmailAddress> = (existingEmails = []) => ({
  fields: [
    {
      name: 'original',
      label: 'Email',
      type: 'email',
      rules: toTypedSchema(
        z
          .string()
          .email()
          .refine(
            (val) =>
              !existingEmails.map((email) => email.original).includes(val),
            { message: 'Email address already added' }
          )
      ),
    },
    {
      name: 'primary',
      label: 'Primary email address',
      type: 'checkbox',
      rules: toTypedSchema(z.boolean()),
    },
  ],
})
