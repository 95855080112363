<script setup lang="ts" generic="T extends any, K extends keyof T">
import { computed, toRef, useSlots } from 'vue'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
  PencilIcon,
} from '@heroicons/vue/24/solid'
import { useEditList } from '.'
import { Btn } from '@/components'

const props = defineProps<{
  items: T[]
  itemKey: K
  sortable?: boolean
  editable?: boolean
  deletable?: boolean
  addable?: boolean
  disableDelete?: (item: T) => boolean
}>()

const emit = defineEmits<{
  (event: 'edit', item: T): void
  (event: 'change', items: T[]): void
  (event: 'cancel'): void
  (event: 'insert', item: T): void
  (event: 'update', newItem: T, oldItem: T): void
  (event: 'remove', removedItem: T): void
}>()

const items = toRef(props, 'items')

const hasHeader = computed(
  () => props.sortable || props.editable || props.deletable
)

// TODO: warn if no form slot but addable/editable are true
const slots = useSlots()
const canAdd = computed(() => slots.form && props.addable)
const canEdit = computed(() => slots.form && props.editable)

const {
  add,
  edit,
  cancel,
  insert,
  update,
  remove,
  moveUp,
  moveDown,
  onEdit,
  onChange,
  onCancel,
  onInsert,
  onUpdate,
  onRemove,
  editIndex,
  editItem,
  editState,
} = useEditList<T>(items)

onEdit((item) => emit('edit', item))
onChange((items) => emit('change', items))
onCancel(() => emit('cancel'))
onInsert((item) => emit('insert', item))
onUpdate((newItem, oldItem) => emit('update', newItem, oldItem))
onRemove((removedItem) => emit('remove', removedItem))
</script>

<template>
  <ul>
    <li
      v-for="(item, i) in items"
      :key="String(item[itemKey as K])"
      class="relative"
    >
      <header
        v-if="hasHeader && i !== editIndex"
        class="dark:shadow-opacity-30 absolute right-5 flex -translate-y-1/3 items-center overflow-hidden rounded border border-gray-300 bg-gray-100 shadow-md shadow-black/10 dark:border-gray-700 dark:bg-gray-800"
      >
        <button
          v-if="sortable"
          class="px-2 py-1 hover:bg-gray-700 disabled:opacity-50"
          :disabled="editState !== 'none' || i === 0"
          @click="moveUp(i)"
        >
          <ChevronUpIcon class="h-4 w-4" />
        </button>

        <button
          v-if="sortable"
          class="px-2 py-1 hover:bg-gray-700 disabled:opacity-50"
          :disabled="editState !== 'none' || i === items.length - 1"
          @click="moveDown(i)"
        >
          <ChevronDownIcon class="h-4 w-4" />
        </button>

        <button
          v-if="canEdit"
          class="px-2 py-1 hover:bg-gray-700 disabled:opacity-50"
          :disabled="editState !== 'none'"
          @click="edit(i)"
        >
          <PencilIcon class="h-4 w-4" />
        </button>

        <button
          v-if="deletable"
          class="px-2 py-1 hover:bg-red-500 disabled:opacity-50"
          :disabled="editState !== 'none' || disableDelete?.(item)"
          @click="remove(i)"
        >
          <TrashIcon class="h-4 w-4" />
        </button>
      </header>

      <slot
        v-if="i === editIndex"
        name="form"
        :item="item"
        :edit-index="editIndex"
        :edit-state="editState"
        :editing="i === editIndex"
        :submit="update"
        :cancel="cancel"
      />

      <slot
        v-else
        :item="item"
        :edit-index="editIndex"
        :edit-state="editState"
        :editing="i === editIndex"
      />
    </li>

    <footer>
      <slot
        v-if="editState === 'add'"
        name="form"
        :edit-index="editIndex"
        :edit-state="editState"
        :editing="editIndex === items.length"
        :item="editItem"
        :submit="insert"
        :cancel="cancel"
      />

      <slot v-if="canAdd && editState === 'none'" name="footer" :add="add">
        <Btn outline class="w-full !border-dashed" @click="add">{{
          $t('actions.add')
        }}</Btn>
      </slot>
    </footer>
  </ul>
</template>
