<script setup lang="ts">
import { computed } from 'vue'
import { DataParam, useApi, useQuery, useSearchQuery } from '@/composables'
import { Btn, Panel, SearchBar, TransitionList } from '@/components'

const { term } = useSearchQuery('term')

const data = computed<DataParam<'organizationsList'>>(() => ({
  search: term.value || undefined,
}))

const api = useApi()
// TODO: Debounce?
const { result } = useQuery(api.v1.organizationsList, data)

const orgs = computed(() => result?.value?.data?.data?.items || [])
const total = computed(() => result?.value?.data?.meta?.totalItems || 0)
const filteredTotal = computed(() => orgs.value.length)
</script>

<template>
  <PageLayout :title="$t('pages.organizations.title')">
    <template #actions>
      <Btn variant="primary" :to="{ name: 'organizations-create' }">{{
        $t('actions.create')
      }}</Btn>
    </template>

    <template #default>
      <SearchBar
        v-model="term"
        :count="filteredTotal"
        :total="total"
        class="mb-5"
      />

      <TransitionList v-slot="{ item: org }" :items="orgs" item-key="id">
        <Panel>
          <router-link
            :to="{ name: 'organizations-edit', params: { id: org.id } }"
          >
            {{ org.name }}
          </router-link>
        </Panel>
      </TransitionList>
    </template>
  </PageLayout>
</template>
