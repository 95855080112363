<script setup lang="ts">
import { defaultOrganization } from '@/models'
import { DynamicForm, DynamicField, organizationCreateSchema } from '@/forms'
import { computed, ref } from 'vue'
import { DataParam, useApi, useQuery } from '@/composables'
import { useRouter } from 'vue-router'

const api = useApi()

const router = useRouter()

const org = ref({
  ...defaultOrganization({ active: true }),
  ownerFullName: '',
  ownerEmailAddress: '',
})

const { result: languageRes } = useQuery(api.v1.languagesList, {})
const languageOptions = computed(
  () =>
    languageRes.value?.data?.data?.items?.map((i) => ({
      label: i.name?.[0].value || '',
      value: i.code || '',
    })) || []
)

const submit = async (values: DataParam<'organizationsCreate'>) => {
  org.value = {
    ...org.value,
    ...values,
  }

  try {
    const response = await api.v1.organizationsCreate({
      name: values.name as string,
      shortName: values.shortName as string,
      defaultLanguageCode: values.defaultLanguageCode as string,
      active: values.active as boolean,
      ownerFullName: values.ownerFullName as string,
      ownerEmailAddress: values.ownerEmailAddress as string,
    })

    router.push({
      name: 'organizations-edit',
      params: { id: response.data.data?.item?.id },
    })
  } catch (err) {
    console.error(err)
  }
}
</script>

<template>
  <PageLayout
    :title="$t('pages.organizationsCreate.title')"
    :breadcrumbs="[
      { text: $t('pages.organizations.title'), to: { name: 'organizations' } },
    ]"
  >
    <DynamicForm
      v-if="org"
      :schema="organizationCreateSchema({ languageOptions })"
      :initial-values="org as DataParam<'organizationsCreate'>"
      :submit-text="$t('actions.save')"
      @submit="submit($event as DataParam<'organizationsCreate'>)"
    >
      <template #field_ownerFullName="{ field }">
        <div class="mb-2 text-xl">Owner</div>
        <DynamicField :field="field" />
      </template>
    </DynamicForm>
  </PageLayout>
</template>
