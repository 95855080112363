import { Plugin, InjectionKey } from 'vue'
import { Api } from '@/models'

export const key = Symbol() as InjectionKey<Api<unknown>>

let api: Api<unknown>

export const getApi = () => api

export default {
  install(app, { baseURL }) {
    api = new Api({ baseURL })

    app.provide(key, api)
  },
} as Plugin
