import { defineStore } from 'pinia'
import { useCookies } from '@vueuse/integrations/useCookies'
import { useJwt } from '@vueuse/integrations/useJwt'
import { getApi } from '@/plugins/apiClient'
import { SelfDetails } from '@/models'

const cookies = useCookies()

const getTokenExpiration = (token: null | string) => {
  if (!token) return new Date()

  const jwt = useJwt(token)
  return new Date((jwt.payload.value?.exp ?? 0) * 1000)
}

export const useAuthStore = defineStore('main', {
  state: (): {
    self: null | SelfDetails
    accessToken: null | string
    refreshTokenTimeout: null | ReturnType<typeof setTimeout>
  } => ({
    self: null,
    refreshTokenTimeout: null,
    accessToken: null,
  }),

  actions: {
    async login(organization: string, email: string, password: string) {
      try {
        const api = getApi()

        const result = await api.v1.authLoginCreate({
          organizationShortName: organization,
          emailAddress: email,
          password,
        })

        this.accessToken = result.data.data.accessToken
        this.storeRefreshToken(result.data.data.refreshToken)

        api.instance.defaults.headers.common.Authorization = `Bearer ${result.data.data.accessToken}`
        this.self = result.data.data
      } catch (err) {
        console.error(err)
      }
    },

    storeRefreshToken(refreshToken: string) {
      const refreshTokenJwt = useJwt(refreshToken)
      cookies.set('refresh_token', refreshToken, {
        expires: new Date((refreshTokenJwt.payload.value?.exp ?? 0) * 1000),
        path: '/',
      })
      this.startRefreshTokenTimer()
    },

    startRefreshTokenTimer() {
      if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout)

      const expires = getTokenExpiration(this.accessToken)
      const timeout = Math.max(expires.getTime() - Date.now() - 60 * 1000, 0) // Refresh 1 minute before it expires

      this.refreshTokenTimeout = setTimeout(this.refresh, timeout)
    },

    async refresh() {
      const refreshToken = cookies.get('refresh_token')

      if (!refreshToken) return

      try {
        const api = getApi()
        const result = await api.v1.authRefreshCreate({ refreshToken })

        this.accessToken = result.data.data.accessToken
        this.storeRefreshToken(result.data.data.refreshToken)

        api.instance.defaults.headers.common.Authorization = `Bearer ${result.data.data.accessToken}`
      } catch (err) {
        console.error(err)
      }

      await this.getUser()
    },

    logout() {
      this.self = null
      this.accessToken = null
      if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout)

      const api = getApi()
      api.instance.defaults.headers.common.Authorization = ''
      cookies.remove('refresh_token')
    },

    async getUser() {
      try {
        const api = getApi()
        const userResult = await api.v1.authSelfList()

        this.self = userResult.data.data ?? null
      } catch (err) {
        console.error(err)
      }
    },

    async tryEnsureLoggedIn() {
      if (this.self) return
      await this.refresh()
    },

    hasPermission(permission: string) {
      if (!this.self) return false
      return this.self.permissions.includes(permission)
    }
  },
})
